@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  margin: 0px !important;
  padding: 0px !important;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
  font-family: "Inter" !important;
  font-weight: normal;
  font-size: 15px !important;
  color: #444444 !important;
  background: #fff;
  line-height: 1.5 !important;
  overflow-x: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a,
button {
  transition: all 300ms ease-in-out;
  outline: none;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.clear {
  clear: both;
}

h1 {
  clear: both;
  color: #444444;
  font-size: 40px;
  padding: 0;
  margin: 0;
  font-family: "Montserrat";
  font-weight: 700;
}

h2 {
  color: #444444;
  font-size: 40px;
  padding: 0 0 10px;
  position: relative;
  margin: 0;
  font-family: "Montserrat";
  font-weight: 700;
}

h3 {
  color: #444444;
  font-size: 26px;
  padding-bottom: 10px;
  margin: 0;
  font-family: "Montserrat";
  font-weight: 700;
}

h4 {
  color: #444444;
  font-size: 22px;
  padding-bottom: 10px;
  margin: 0;
  font-family: "Montserrat";
  font-weight: 700;
}

h5 {
  color: #444444;
  font-size: 20px;
  padding-bottom: 10px;
  margin: 0;
  font-family: "Montserrat";
  font-weight: 700;
}

h6 {
  color: #444444;
  font-size: 14px;
  padding-bottom: 5px;
  margin: 0;
  font-family: "Montserrat";
  font-weight: 700;
}

.relative {
  position: relative;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.pad_10-20 {
  padding: 10px 20px;
}

.pad-15 {
  padding: 15px;
}

.p-v-20-30 {
  padding: 20px 0 30px 0;
}

.p-t-30 {
  padding: 30px 0 0 0;
}

.p-v-75 {
  padding: 75px 0;
}

.padding_60_20 {
  padding: 0 20px 0 60px;
}

.padding_20_60 {
  padding: 0 60px 0 20px;
}

.padding_33_30 {
  padding: 0 33px 0 30px;
}

.p-v-10 {
  padding: 10px 0;
}

.pad_30 {
  padding: 30px;
}

.padding_60_30 {
  padding: 0 30px 25px 60px;
}

.padding_15_30 {
  padding: 0 30px 25px 15px;
}

.pad_0_31 {
  padding: 0 31px;
}

.pad_0_30_0 {
  padding: 0 30px 0 0;
}

.pad_15_hor {
  padding: 0 15px;
}

.pad_10_hor {
  padding: 0 10px;
}

.pad_20_hor {
  padding: 0 20px;
}

.pad_30_hor {
  padding: 0 30px;
}

.p-h-30 {
  padding: 0 30px;
}

.pad_30_ver {
  padding: 30px 0;
}

.pad-v20 {
  padding: 20px 0 !important;
}

.pad-v15 {
  padding: 15px 0;
}

.p-v-30 {
  padding: 30px 0;
}

.p-v-40 {
  padding: 40px 0;
}

.p-v-60 {
  padding: 60px 0;
}

.p-v-60-30 {
  padding: 60px 0 30px;
}

.pad-20-10 {
  padding: 20px 10px;
}

.pad_0_30_0_80 {
  padding: 0 30px 80px 30px;
}

.pad_0_15_0_30 {
  padding: 0 15px 30px 15px;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-h-40 {
  padding: 0 40px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-v-40-60 {
  padding: 40px 0 60px;
}

.ph-40 {
  padding: 0 40px;
}

.ph-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-55 {
  margin-top: 55px;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-t-80 {
  margin-top: 80px;
}

.p-h-15 {
  padding: 0 15px;
}

.p-h-20 {
  padding: 0 20px;
}

.m-v15-40 {
  margin: 15px 0 40px;
}

.m-v-55-40 {
  margin: 55px 0 40px;
}

.pad_25-0 {
  padding: 25px 0;
}

.pad_20-0 {
  padding: 20px 0;
}

.pad_15-0 {
  padding: 15px 0;
}

.pad_20 {
  padding: 20px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-10 {
  margin-left: 10px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-40 {
  padding-left: 40px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.dt-sc-margin55 {
  margin: 25px 0 30px;
}

.uc {
  text-transform: uppercase;
}

.bCover {
  background-size: cover !important;
}

p {
  margin: 0;
}

.noBdr {
  border: none !important;
}

.overflowHidden {
  overflow: hidden;
}

.imgContainer {
  width: 100%;
}
.imgContainer a {
  display: block;
}
.imgContainer img {
  width: 100%;
  display: block;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.input-1 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: #eee 1px solid;
  height: 42px;
}

.input-2 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: #e4e4e4 1px solid;
  height: 42px;
  border-radius: 6px;
}

.input-3 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: none;
  border-bottom: #b7b7b7 1px solid;
  height: 50px;
}
.input-3:focus {
  box-shadow: none !important;
  outline: none;
}

.input-4 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-family: "Montserrat";
  font-size: 18px;
  line-height: 1.5;
  color: #fff !important;
  background-color: transparent;
  border: #fff 2px solid;
  height: 54px;
}
.input-4::-webkit-input-placeholder {
  font-size: 18px;
  color: #fff;
}
.input-4:-moz-placeholder {
  font-size: 18px;
  color: #fff;
}
.input-4::-moz-placeholder {
  font-size: 18px;
  color: #fff;
}
.input-4:-ms-input-placeholder {
  font-size: 18px;
  color: #fff;
}
.input-4:focus {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: none !important;
  outline: none;
}

.input-5 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: #bcddbe 1px solid;
  border-radius: 6px;
  height: 54px;
  color: #333;
}
.input-5::-webkit-input-placeholder {
  font-size: 18px;
  color: #333;
  opacity: 1;
}
.input-5:-moz-placeholder {
  font-size: 18px;
  color: #333;
  opacity: 1;
}
.input-5::-moz-placeholder {
  font-size: 18px;
  color: #333;
  opacity: 1;
}
.input-5:-ms-input-placeholder {
  font-size: 18px;
  color: #333;
  opacity: 1;
}
.input-5:focus {
  outline: none;
}

.selectOption {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-size: 10px;
  padding: 0 0.75rem;
  border: #eee 1px solid;
  width: 100%;
  height: 42px;
}

.selectOption-3 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-size: 10px;
  padding: 0 0.75rem;
  border: #eee 1px solid;
  width: 100%;
  border: none;
  border-bottom: #b7b7b7 1px solid;
  height: 50px;
}
.selectOption-3:focus {
  box-shadow: none !important;
}

.textArea-1 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-family: "Montserrat";
  font-size: 18px;
  line-height: 1.5;
  color: #fff !important;
  background-color: transparent;
  border: #fff 2px solid;
  resize: none;
  height: 136px;
}
.textArea-1::-webkit-input-placeholder {
  font-size: 18px;
  color: #fff;
}
.textArea-1:-moz-placeholder {
  font-size: 18px;
  color: #fff;
}
.textArea-1::-moz-placeholder {
  font-size: 18px;
  color: #fff;
}
.textArea-1:-ms-input-placeholder {
  font-size: 18px;
  color: #fff;
}
.textArea-1:focus {
  box-shadow: none !important;
  background: rgba(255, 255, 255, 0.2);
}

.textArea-2 {
  display: block;
  width: 100%;
  padding: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  color: #333;
  border: #bcddbe 1px solid;
  border-radius: 6px;
  resize: none;
  height: 136px;
}
.textArea-2::-webkit-input-placeholder {
  font-size: 18px;
  color: #333;
}
.textArea-2:-moz-placeholder {
  font-size: 18px;
  color: #333;
}
.textArea-2::-moz-placeholder {
  font-size: 18px;
  color: #333;
}
.textArea-2:-ms-input-placeholder {
  font-size: 18px;
  color: #333;
}
.textArea-2:focus {
  outline: none;
}

.form-group {
  margin-bottom: 0.5rem;
}

header {
  width: 100%;
  z-index: 3;
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px 0;
}
header Grid {
  background-color: transparent;
}
header .navigation ul li a {
  display: block;
  padding: 10px 20px;
  color: #a3a1a1;
}
header .navigation ul li a i {
  margin-left: 5px;
}
header .navigation ul li a:hover {
  color: #fff;
}
header .phoneUser ul li {
  margin-left: 30px;
}
header .phoneUser ul li a {
  color: #fff;
}

.mainFooter {
  background: url(../../../public/images/footer-bg.png) top center no-repeat #3f3f3f;
  background-size: cover;
  position: relative;
}
.mainFooter::before {
  content: "";
  position: absolute;
  bottom: calc(100% - 1px);
  left: 0;
  width: 100%;
  height: 101px;
  background: url(../../../public/images/footer-before.png) top center repeat-x;
}

.homeBanner {
  height: 100vh;
  background-size: cover !important;
  position: relative;
}
.homeBanner::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: url(../../../public/images/banner-background.png) bottom center no-repeat;
  background-size: cover;
  z-index: 1;
}
.homeBanner::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.banSlogan {
  width: 100%;
  max-width: 1170px;
  position: absolute;
  bottom: 13%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}
.banSlogan h1 {
  font-family: "Fair Prosper";
  color: #f97150;
  text-align: center;
  font-size: 7.4vw;
}

.banSrc {
  box-shadow: 6px 3.605px 15.04px 0.96px rgba(94, 94, 94, 0.32);
  max-width: 810px;
  width: 100%;
  margin: 10vh auto 0;
  border-radius: 50px;
  background: #fff;
}
.banSrc .banSrcInput {
  border-radius: 50px;
  width: 100%;
  padding: 0 30px;
  border: none;
}
.banSrc .banSrcInput.MuiFormControl-root {
  padding: 0;
}
.banSrc .MuiOutlinedInput-notchedOutline {
  border: none;
}
.banSrc .css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 19px 30px;
}

.destinationsection {
  position: relative;
  z-index: 1;
  padding-bottom: 7rem;
}

.destinationBox {
  overflow: hidden;
  border-radius: 16px !important;
  text-align: center;
  display: block;
  color: #fff !important;
}
.destinationBox:after {
  content: "";
  position: absolute;
  opacity: 0.6;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 0.4s ease-in-out;
  background: #d248fa;
  background: linear-gradient(135deg, #d248fa 0%, #fb5d36 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#d248fa", endColorstr="#fb5d36",GradientType=1 );
}
.destinationBox h4 {
  color: #fff;
  text-transform: uppercase;
  transition: all ease-in-out 0.4s;
  font-weight: 700;
  font-size: 32px;
  position: relative;
  transform: translateY(75%);
}
.destinationBox h4 span {
  display: flex;
  font-weight: 600;
  line-height: 1;
  justify-content: center;
}
.destinationBox h4 span small {
  border: #fff 1px solid;
  border-radius: 30px;
  padding: 5px 15px;
  font-size: 15px;
}
.destinationBox img {
  width: 100%;
  display: block;
  transition: opacity 0.7s, transform 0.7s;
}
.destinationBox .destinationBoxInner * {
  box-sizing: padding-box;
  transition: all 0.3s ease-out;
}
.destinationBox .destinationBoxInner {
  padding: 20px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
.destinationBox .destinationBoxInner .dbiHide {
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.4s;
}
.destinationBox .destinationBoxInner h5 {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}
.destinationBox:hover img {
  opacity: 0.2;
  filter: blur(5px);
  transform: scale(1.1);
}
.destinationBox:hover:after {
  transform-origin: bottom;
  transform: scaleY(1);
}
.destinationBox:hover .dbiHide {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.2s;
  transform: translateY(0);
}
.destinationBox:hover h4 {
  opacity: 1;
  border-color: rgba(255, 255, 255, 0.5);
  transition-delay: 0.2s;
  transform: translateY(0);
}

.destinationRight .LearnMoreBtn {
  font-family: "Montserrat";
  font-weight: 800 !important;
  box-shadow: none;
}
.destinationRight .pattern-1 {
  position: absolute;
  top: -50px;
  right: 150px;
  z-index: -1;
}
.destinationRight .pattern-2 {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
  z-index: -1;
}
.destinationRight .pattern-3 {
  position: absolute;
  bottom: -50px;
  right: 150px;
  z-index: -1;
}

.mt-L20 {
  margin-top: -20px;
}

.destinationRight {
  padding: 30px 60px;
}
.destinationRight h5 {
  font-family: "Amertha PERSONAL USE ONLY";
  color: #f97150;
  font-size: 30px;
  padding: 0;
}
.destinationRight h2 {
  font-size: 40px;
  color: #444444;
}
.destinationRight p {
  color: #444444;
}

.destinationNumber {
  margin: 60px 0;
}
.destinationNumber ul li {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  color: #444444;
  flex: 0 0 130px;
  max-width: 130px;
  text-transform: uppercase;
}
.destinationNumber ul li span {
  display: block;
  font-size: 46px;
  color: #f97150;
  font-family: "Inter";
  font-weight: bold;
}

.popularToursBox {
  display: block;
  border-radius: 10px !important;
  overflow: hidden !important;
  position: relative;
}
.popularToursBox img {
  display: block;
  width: 100%;
}
.popularToursBox .newSpan {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 10px 15px;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
}
.popularToursBox .newSpan.bgParple {
  background: #92278f;
}
.popularToursBox .newSpan.bgGreen {
  background: #39b54a;
}
.popularToursBox .transBgBlack {
  content: "";
  position: absolute;
  bottom: 0;
  height: 25%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00000000", endColorstr="#a6000000",GradientType=0 );
  transition: all ease-in-out 0.6s;
}
.popularToursBox:hover .ptbWrap {
  bottom: 0;
}
.popularToursBox:hover .ptbWrap .ptbInner,
.popularToursBox:hover .ptbWrap .dolerPp {
  opacity: 1;
  visibility: visible;
}
.popularToursBox:hover .transBgBlack {
  height: 100%;
}

.ptbWrap {
  position: absolute;
  bottom: -30%;
  width: 100%;
  left: 0;
  z-index: 1;
  color: #fff;
  transition: all ease-in-out 0.6s;
  padding: 20px 0;
}
.ptbWrap h4 {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 0 20px;
}
.ptbWrap .ptbInner {
  padding: 0 20px;
  opacity: 0;
  visibility: hidden;
  margin: 10px 0 20px;
  transition: all ease-in-out 0.6s;
  font-size: 12px;
}
.ptbWrap .ptbInner ul {
  margin-top: 10px;
}
.ptbWrap .ptbInner ul li {
  font-weight: 600;
}
.ptbWrap .enquiry {
  margin: 20px 0 0 20px;
  font-size: 14px;
  color: #fff;
  font-family: "Montserrat";
  font-weight: 700;
}
.ptbWrap .enquiry svg {
  font-size: 13px;
  line-height: 1;
  position: relative;
  top: 2px;
}

.dolerPp {
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.8s;
  padding: 10px 20px;
  background: linear-gradient(to right, rgb(251, 93, 54) 46%, rgba(251, 93, 54, 0.64) 60%, rgba(94, 35, 20, 0) 85%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#fb5d36", endColorstr="#00000000",GradientType=1 );
}
.dolerPp span {
  text-decoration: line-through;
}
.dolerPp h6 {
  padding-bottom: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
}
.dolerPp h6 span {
  font-weight: 600;
}
.dolerPp h5 {
  padding-bottom: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}
.dolerPp h5 sup {
  font-size: 14px;
}

.popularTours {
  padding-top: 2.5rem;
  padding-bottom: 3rem;
}

.sectionTitle h5 {
  font-family: "Amertha PERSONAL USE ONLY";
  color: #f97150;
  font-size: 30px;
  padding: 0;
  font-weight: normal;
}
.sectionTitle h2 {
  font-size: 40px;
}

.waveAnimation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  opacity: 0.5;
}

.ourWorkBox {
  display: block;
  padding: 30px;
  box-shadow: 8.572px 5.15px 6.8px 1.2px rgba(54, 56, 56, 0.05) !important;
  position: relative;
  overflow: hidden;
  background: #fff !important;
  z-index: 1;
  height: 100%;
  border-radius: 10px !important;
}
.ourWorkBox span {
  width: 80px;
  height: 80px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  position: relative;
}
.ourWorkBox span img {
  width: 45px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ourWorkBox h3 {
  transition: all ease-in-out 0.6s;
  font-size: 1.2rem;
}
.ourWorkBox small {
  border: #444444 1px solid;
  border-radius: 30px;
  padding: 5px 10px;
  transition: all ease-in-out 0.8s;
}
.ourWorkBox:after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
  background: #d248fa;
  background: linear-gradient(135deg, #d248fa 0%, #fb5d36 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#d248fa", endColorstr="#fb5d36",GradientType=1 );
}
.ourWorkBox:hover:after {
  transform-origin: left;
  transform: scaleX(1);
}
.ourWorkBox:hover h3 {
  color: #fff;
}
.ourWorkBox:hover small {
  border-color: #fff;
  color: #fff;
}

.tourPacLeft {
  height: 260px;
  background-size: cover !important;
  position: relative;
  border-radius: 16px 0 0 16px;
  overflow: hidden;
}
.tourPacLeft .dolerPp {
  opacity: 1;
  visibility: visible;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
}

.dudiAct li span {
  display: inline-block;
  width: 18px;
  vertical-align: middle;
  margin-right: 3px;
}
.dudiAct li span img {
  width: 100%;
  display: inline-block;
}

.ptsWrap {
  background: #fff;
  padding: 15px;
  border-radius: 0 0 16px 16px;
}
.ptsWrap h4 {
  line-height: 1;
}
.ptsWrap h4 a {
  color: #444;
  font-size: 18px;
}

.ptsWrapTop {
  border-radius: 16px 16px 0 0;
  overflow: hidden;
}
.ptsWrapTop > img {
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}
.ptsWrapTop .newSpan {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
.ptsWrapTop .newSpan.bgParple {
  background: #92278f;
}
.ptsWrapTop .newSpan.bgGreen {
  background: #39b54a;
}
.ptsWrapTop .dolerPp {
  opacity: 1;
  visibility: visible;
  position: absolute;
  bottom: 60px;
  width: 100%;
}

.aboutInner h2 {
  font-size: 200px;
}

.aboutIcons li {
  flex: 0 0 16%;
  max-width: 16%;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 700;
}
.aboutIcons li:not(:last-child) {
  margin-right: 20px;
}
.aboutIcons li:first-child {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 1.5rem;
}

.roundIcon {
  background: #d656ee;
  background: linear-gradient(135deg, #d656ee 0%, #f75e50 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#d656ee", endColorstr="#f75e50",GradientType=1 );
  height: 66px;
  width: 66px;
  line-height: 66px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: 10px;
}

.aboutSlider .aboutInnerBox {
  display: block;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 16px -9px;
}
.aboutSlider .aboutInnerBox:not(:last-child) {
  margin-bottom: 15px;
}

.aboutSlider .owl-stage-outer {
  padding: 20px 0;
}

.pinkBg {
  background: #fce8f4 !important;
}

.aboutSlider {
  position: relative;
}
.aboutSlider .owl-prev,
.aboutSlider .owl-next {
  position: absolute;
  top: 50%;
  width: 34px;
  height: 34px;
  border-radius: 50% !important;
  margin: 0;
  transform: translateY(calc(-50% - 7px));
}
.aboutSlider .owl-prev.disabled,
.aboutSlider .owl-next.disabled {
  display: none !important;
}
.aboutSlider .owl-prev span,
.aboutSlider .owl-next span {
  font-size: 30px;
  line-height: 20px;
}
.aboutSlider .owl-prev {
  left: -44px;
}
.aboutSlider .owl-next {
  right: -44px;
}

.sellerParalax {
  height: 450px;
  position: relative;
}
.sellerParalax:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #194204;
  opacity: 0.6;
}
.sellerParalax:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 74px;
  width: 100%;
  background: url(../../../public/images/best-seller-pattern.png) center center no-repeat;
  z-index: 1;
  background-size: cover;
}

.bestSellerRight {
  position: absolute;
  top: 4px;
  right: -7px;
  padding: 19px;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  transform: rotate(46deg);
  display: block;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  width: 100px;
}
.bestSellerRight:after {
  content: "";
  position: absolute;
  top: -36px;
  right: -12px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  background: #fb5d36;
  width: 120px;
  height: 120px;
  z-index: -1;
  border-radius: 50% 0 50% 50%;
}

.bestSellerLeft {
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 1;
  max-width: 300px;
}
.bestSellerLeft span {
  display: block;
}
.bestSellerLeft span img {
  max-width: 100%;
  display: block;
}

.bestSellerDescArea {
  margin-top: -300px;
  position: relative;
  z-index: 1;
}

.bestSellerRoundImg img {
  width: 100%;
  display: block;
}

.bsrImgWrap {
  width: 100%;
  border-radius: 100% !important;
  overflow: hidden;
  padding: 20px;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 20px -10px !important;
}

.bestSellerRoundImg img {
  width: 100%;
  display: block;
  border-radius: 50%;
}

.bsri-1 {
  position: relative;
  right: -70px;
}

.bsri-2 {
  position: relative;
  z-index: 1;
}

.bsri-3 {
  position: relative;
  left: -70px;
}

.ratingArea li {
  white-space: nowrap;
}
.ratingArea li strong {
  white-space: nowrap;
}

.bestSellerActi .dudiAct {
  padding: 10px 0;
}
.bestSellerActi .dudiAct li {
  font-size: 26px;
  color: #444444;
  text-align: center;
  padding: 0 30px;
  font-weight: 700;
}
.bestSellerActi .dudiAct li span {
  width: 60px;
  margin-right: 15px;
}

.oldNewRate {
  text-align: center;
}
.oldNewRate small {
  text-decoration: line-through;
  font-size: 30px;
  color: #82807f;
  font-weight: 400;
}
.oldNewRate span {
  display: block;
  border-radius: 50px;
  line-height: 56px;
  border: #f97150 5px solid;
  padding: 0 50px;
  font-size: 36px;
  color: #f97250;
  font-weight: 400;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.pl-15 {
  padding-left: 15px;
}

.lientSaysParalax {
  padding: 4rem 0 14rem;
}

.clientSayBox {
  height: 100%;
  position: relative;
  padding: 30px 30px 80px;
  border-radius: 20px;
  background: url(../../../public/images/client-say-box-bg.png) bottom -15px right 50px #fff no-repeat !important;
}
.clientSayBox.Height-400 {
  min-height: 350px;
}
.clientSayBox .cbRateDate {
  position: absolute;
  bottom: 27px;
  width: 100%;
  left: 0;
  padding: 0 29px;
}

.fLink * {
  color: #fff !important;
  margin: 0;
}

.bestSellerDescBtm {
  padding-bottom: 4rem;
}

.aboutBan,
.StepBan {
  z-index: 1;
}

.aboutBan:after,
.StepBan:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  left: 0;
  top: 0;
}

.aboutBanSlogan {
  z-index: 1;
}

.aboutBan::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100px;
  background: url(../../../public/images/about/1.svg) no-repeat;
  left: 0;
  bottom: 0;
  z-index: 1;
  background-size: cover;
}

.aboutSec-2 {
  padding: 2rem 0;
}

.counterCard {
  margin: 0 auto;
}

.counterSection {
  padding: 2rem 0;
}

.aboutSec-3 {
  padding: 3rem 0;
  overflow: hidden;
}

.missionImg {
  padding-left: 150px !important;
}
.missionImg img {
  display: block;
  width: 100%;
}
.missionImg .missionMid {
  box-shadow: rgba(255, 255, 255, 0.6) 0 0 20px;
  border-radius: 10px;
  overflow: hidden;
  height: 448px;
  width: 448px;
}
.missionImg .missionMid img {
  height: 448px;
  width: 448px;
  -o-object-fit: cover;
     object-fit: cover;
}
.missionImg .missionTop {
  position: absolute;
  top: -106px;
  right: 320px;
  box-shadow: none;
  border-radius: 10px;
  overflow: hidden;
  width: 290px;
  height: 290px;
}
.missionImg .missionTop img {
  height: 290px;
  -o-object-fit: cover;
     object-fit: cover;
}
.missionImg .missionBtm {
  position: absolute;
  bottom: -106px;
  right: 320px;
  z-index: -1;
  box-shadow: none;
  border-radius: 10px;
  overflow: hidden;
  width: 290px;
  height: 290px;
}
.missionImg .missionBtm img {
  height: 290px;
  -o-object-fit: cover;
     object-fit: cover;
}

.serviceExcellence {
  padding: 3rem 0 0;
}

.roundIcon-2 {
  height: 50px;
  width: 50px;
  display: block;
  margin-bottom: 1.6rem;
}
.roundIcon-2 img {
  height: 100%;
  display: block;
}

.mainActivityParalax {
  padding: 2rem 0;
}

.weAreMember {
  padding: 1rem 0 8.5rem;
}

.aboutBan img {
  width: 100%;
}

.MuiButtonBase-root.Mui-expanded {
  background: #f97150 !important;
  color: #fff !important;
}

.faqAccordion .MuiButtonBase-root.Mui-expanded {
  background: #fff !important;
  color: #333 !important;
}

.acordianSrc {
  margin-bottom: 25px;
}
.acordianSrc .acordianSrcInput {
  height: 70px;
  border-radius: 50px;
  width: 100%;
  padding: 0 30px;
  border: none;
  font-size: 15px;
  font-family: "Inter";
  box-shadow: rgba(0, 0, 0, 0.15) 0 18px 10px -5px;
}
.acordianSrc .acordianSrcBtn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 54px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  background: #b7b7b7;
  color: #fff;
  border-radius: 50%;
  border: none;
  font-size: 30px;
}

.MuiDrawer-paper {
  background: #b85036 !important;
}

.testHeader {
  background-color: transparent !important;
  position: absolute !important;
  top: 0px;
  z-index: 999999;
}

.test {
  background-color: #173850 !important;
  position: fixed !important;
  top: 0px;
}

.itinerayBann .MuiRating-icon.MuiRating-iconEmpty {
  color: #faaf00;
}

.overViewArea ul li a {
  font-weight: 700;
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0;
  padding: 0 10px;
  display: block;
  line-height: 52px;
  transition: all 0.5s;
  color: #636363;
  position: relative;
}
.overViewArea ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: #f97150;
  opacity: 0;
  visibility: hidden;
}
.overViewArea ul li a svg {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}
.overViewArea ul li a.active-scroll-spy {
  color: #f97150;
}
.overViewArea ul li a.active-scroll-spy::after {
  opacity: 1;
  visibility: visible;
}

.acc {
  z-index: 999999 !important;
}

.megaMenu {
  background: #f6f6f6;
  max-width: 800px;
  width: 100%;
}

.megaMenuLeft {
  flex: 0 0 200px;
  max-width: 200px;
  border-right: #eee 1px solid;
  background: #fff;
}

.megaMenuRight {
  flex: 0 0 calc(100% - 200px);
  max-width: calc(100% - 200px);
  padding: 30px 50px 30px 30px;
  font-size: 13px;
  background: #f6f6f6;
}
.megaMenuRight .bdrLeft {
  border-left: #ccc 1px solid;
}
.megaMenuRight .css-1sqktie-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: transparent;
}
.megaMenuRight .noWrap {
  white-space: nowrap;
}

.font-12 {
  font-size: 12px !important;
}

.itineraryRight {
  position: sticky;
  top: 120px;
}

.tsReboon span {
  background: #444;
  display: inline-block;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  font-style: italic;
  padding: 7px 60px;
  border-radius: 0 0 10px 10px;
  line-height: 1.8;
}

.allInclusive {
  font-size: 14px !important;
  color: #fff;
  text-align: center;
  font-family: Montserrat !important;
  margin-bottom: 5px !important;
}
.allInclusive span {
  font-weight: 700;
  font-size: 18px;
  display: inline-block;
}

.datePerson label {
  color: #fff !important;
  font-size: 14px;
}
.datePerson input {
  color: #fff !important;
}
.datePerson svg {
  color: #fff !important;
}

.whyBookUs p {
  margin: 15px 0;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
}

.overViewArea {
  position: sticky;
  top: 0;
  z-index: 9;
}

.pericePersonDd .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11.5px 14px;
}

.pericePersonDd .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #f97150;
}

.pericePersonDd .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 10px) scale(1) !important;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
}

.tourPackageSlider {
  position: relative;
}
.tourPackageSlider .owl-prev,
.tourPackageSlider .owl-next {
  position: absolute;
  top: 50%;
  width: 34px;
  height: 34px;
  border-radius: 50% !important;
  margin: 0;
  transform: translateY(calc(-50% - 7px));
}
.tourPackageSlider .owl-prev.disabled,
.tourPackageSlider .owl-next.disabled {
  display: none !important;
}
.tourPackageSlider .owl-prev span,
.tourPackageSlider .owl-next span {
  font-size: 30px;
  line-height: 20px;
}
.tourPackageSlider .owl-prev {
  left: -44px;
}
.tourPackageSlider .owl-next {
  right: -44px;
}

.fromUsppBtn {
  color: #444444 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Montserrat" !important;
}
.fromUsppBtn span {
  font-weight: 400 !important;
  font-size: 60% !important;
  margin-right: 10px;
}
.fromUsppBtn sub {
  font-size: 50% !important;
  margin-left: 5px;
}

.ddMenu {
  font-family: "Montserrat" !important;
}
.ddMenu:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #fff transparent;
}

.mmLink {
  background-color: transparent !important;
}
.mmLink:hover {
  color: #f97150 !important;
}

.siteNav {
  padding: 15px 0;
}

.siteNav .active:after {
  content: "";
  position: absolute;
  bottom: -28px;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 20px;
  border-color: transparent transparent #fff transparent;
  transform: translateX(-50%);
}

.menuInner-2 {
  width: 500px !important;
}

.menuInner-3 {
  width: 200px !important;
}

.smallMenu {
  padding: 15px;
  position: relative;
}

.gierList ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.gierList ul li {
  flex: 0 0 33.333%;
  margin: 10px 0;
  max-width: 33.333%;
  position: relative;
  padding-left: 20px;
}

.gierList ul li:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  left: 0;
  top: 6px;
  border-radius: 50%;
  background: #f97150;
}

.gierList ul li:before {
  content: "";
  position: absolute;
  left: 3px;
  top: 9px;
  background: #fff;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  z-index: 1;
}

.megamenuOuter {
  top: 0 !important;
  right: 200px !important;
}

.offerDiscountTable table {
  border: none !important;
}
.offerDiscountTable table th,
.offerDiscountTable table td {
  padding: 0.5rem 2rem;
  border: none !important;
  text-align: center;
}
.offerDiscountTable table th .ltProperties,
.offerDiscountTable table td .ltProperties {
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
  font-size: 90% !important;
  color: #929191;
}

.roundNumber {
  margin-top: 1rem;
}

.stepArea {
  padding: 1rem 0 7rem;
}

.stepArea * {
  font-family: "Montserrat" !important;
}

.additionalService table td,
.additionalService table th {
  border: none;
  padding: 0;
}
.additionalService .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px 14px;
  font-size: 13px;
}

.colOrange {
  color: #f97150 !important;
}

.addressFlex ul .info {
  flex: 0 0 120px;
  max-width: 120px;
}
.addressFlex ul .value {
  flex-grow: 1;
}
.addressFlex ul:not(:last-child) {
  margin-bottom: 0.8rem;
}

.paymentAreaInner {
  background: #fff;
  width: 100%;
  padding: 1rem 2rem;
}

.cardImg img {
  max-width: 100%;
  display: block;
}

.nxtBtnStep {
  background: #f97150 !important;
  color: #fff !important;
  padding: 6px 34px !important;
}

.finishBtnStep {
  background: #737373 !important;
  color: #fff !important;
  padding: 6px 34px !important;
}

.forSticky {
  position: sticky;
  top: 20px;
}

.stepWrapArea {
  padding: 2rem 0 7rem;
}

.tabLeft {
  width: 100%;
}
.tabLeft button {
  text-align: left !important;
  align-items: start !important;
  text-transform: capitalize;
  font-size: 1.1rem;
  color: #444;
  padding: 0;
}
.tabLeft button:not(:last-child) {
  margin-bottom: 5px;
}
.tabLeft button.Mui-selected {
  color: #f97150;
}

.tabDtls .css-19kzrtu {
  padding: 0;
}
.tabDtls .css-ahj2mt-MuiTypography-root {
  font-size: 1.1rem;
}

.tabLeft {
  border: #ddd 1px solid;
  border-radius: 6px;
  position: sticky;
  top: 50px;
  padding: 1rem;
}

.filterWrap {
  background: #f4f4f4;
}

.filterLeft {
  background: #fff;
  border-radius: 10px;
  border: #ddd 1px solid;
}

.filterLeftInner {
  padding: 1.2rem;
  border-top: #ddd 1px solid;
}

.filterLeftInner ul li span {
  font-family: "Montserrat";
  font-weight: 700;
}

.yellowColor {
  color: #eed651;
}

.grayColor {
  color: #cfcfcf;
}

.SortByTop {
  padding: 0 8px;
}
.SortByTop ul li {
  flex: 1 0 0%;
}
.SortByTop ul li:not(:last-child) {
  margin-right: 0.8rem;
}

.ptsWrapTop > img {
  display: block;
  width: 100%;
}

.filterWrap {
  padding: 0 0 7rem;
}

.mt-5 {
  margin-top: 5rem;
}

.pegiTabColor .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff;
  background-color: #f26522;
}

.radio-wrap {
  position: relative;
  margin: 3rem 0;
}
.radio-wrap .inside {
  background-color: #fff;
  padding: 60px 0 30px;
  transition: all 0.4s ease;
  position: relative;
  text-align: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 8px 20px -10px;
}
.radio-wrap .inside span {
  position: absolute;
  left: 50%;
  top: -40px;
  height: 80px;
  width: 80px;
  background: #f4f8fd;
  transform: translateX(-50%);
  line-height: 100px;
  border-radius: 50%;
  transition: all 0.4s ease;
  border: #fff 3px solid;
}
.radio-wrap .inside span svg {
  font-size: 30px;
  transition: all 0.4s ease;
}
.radio-wrap > span {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.Mui-checked {
  color: #1976d2;
}

.radio-wrap.baranjhatta .inside,
.radio-wrap.baranjhatta span {
  border-color: #fff !important;
  background-color: #ffd7cd !important;
}

.familyDropDown ul li {
  flex: 0 0 200px;
  max-width: 200px;
}
.familyDropDown ul li:not(:last-child) {
  margin-right: 20px;
}

.checkbox-wrap {
  position: relative;
}
.checkbox-wrap .inside {
  background-color: #fff;
  transition: all 0.4s ease;
  position: relative;
  text-align: center;
  border-radius: 5px;
  border: #ddd 1px solid;
}

.checkbox-wrap input:checked ~ .inside {
  border-color: #0260c0 !important;
  background-color: #0260c0 !important;
}

.lookingFor li.radio-wrap {
  margin: 0.5rem 0;
}
.lookingFor li.radio-wrap:not(:last-child) {
  margin-right: 1.1rem;
}
.lookingFor li.radio-wrap .inside {
  padding: 0.7rem 2rem;
  display: flex;
  align-items: center;
  border: #eee 1px solid;
}
.lookingFor li.radio-wrap .inside span {
  position: static;
  height: 20px;
  width: 20px;
  background: #fff;
  transform: translateX(0);
  line-height: 18px;
  border: #9d9d9d 1px solid;
  margin-right: 0.8rem;
}
.lookingFor li.radio-wrap .inside span svg {
  font-size: 10px;
  color: #fff;
}
.lookingFor li.radio-wrap .inside h6 {
  padding: 0 !important;
}
.lookingFor li.radio-wrap .Mui-checked ~ .inside span {
  border-color: #373737 !important;
}
.lookingFor li.radio-wrap .Mui-checked ~ .inside span svg {
  color: #373737;
}
.lookingFor li.radio-wrap:hover .css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover ~ .inside span {
  border-color: #373737 !important;
}
.lookingFor li.radio-wrap:hover .css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover ~ .inside span svg {
  color: #373737;
}

.personalInfo {
  margin: 3rem auto 0;
  max-width: 700px;
}

.rangeSlider {
  margin: 0 auto;
  max-width: 950px;
  width: 100%;
  background: #f6f6f6;
  padding: 2rem;
}

.banIcon {
  background: #f97150;
  background: #f97150;
  display: block;
  width: 90px;
  height: 90px;
  color: #fff;
  font-size: 60px;
  line-height: 102px;
  border-radius: 6px;
  text-align: center;
  position: absolute;
  bottom: 45px;
  right: 100px;
  z-index: 1;
}

.BreakdownTable table td {
  padding: 0.8rem 0;
}

.tbn {
  border-bottom: none !important;
}

.signInBtn {
  font-size: 0.675rem;
  line-height: 2.5;
  width: 100%;
  background: #f97150;
  border-radius: 6px;
  border: none;
  color: #fff;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 1rem;
}
.signInBtn:hover {
  background: #333;
  color: #fff;
}

.signInBtnBdr {
  font-size: 0.675rem;
  line-height: 2.5;
  width: 100%;
  background: #fff;
  border: #f97150 1px solid;
  border-radius: 6px;
  color: #f97150;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 1rem;
}
.signInBtnBdr:hover {
  background: #f97150;
  color: #fff;
}

.forgetPassword {
  color: #333;
  font-weight: 600;
}
.forgetPassword:hover {
  color: #f97150;
}

.ViewBookingBanner {
  height: 400px;
  width: 100%;
  position: relative;
  background-size: cover !important;
  z-index: 1;
}
.ViewBookingBanner:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.viewBooking {
  padding: 2.5rem 0 8rem;
}

.mailPrint ul li:not(:last-child) {
  margin-right: 1.2rem;
}
.mailPrint ul li a {
  color: #333;
  font-weight: 600;
}
.mailPrint ul li a svg {
  color: #f97150;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
}
.mailPrint ul li a:hover {
  color: #f97150;
}

.viewImg {
  position: sticky;
  top: 20px;
}
.viewImg img {
  width: 100%;
  display: block;
}

.campTrek ul:not(:last-child) {
  margin-bottom: 3px;
}
.campTrek ul li.campTracInfo {
  flex: 0 0 120px;
  max-width: 120px;
}
.campTrek ul li.campTracVal {
  flex: 0 0 calc(100% - 150px);
  max-width: calc(100% - 150px);
  position: relative;
  padding-left: 14px;
  font-weight: 500;
}
.campTrek ul li.campTracVal:after {
  content: ":";
  position: absolute;
  top: 0;
  left: 0;
}

.campAmountArea {
  background: #fff0ed;
  padding: 1.5rem;
  border-radius: 10px;
}
.campAmountArea .campAmount:not(:last-child) {
  margin-bottom: 15px;
}
.campAmountArea .campAmount li {
  font-size: 1rem;
  font-weight: 600;
  position: relative;
}
.campAmountArea .campAmount li:first-child {
  margin-right: 10px;
  flex: 130px;
  max-width: 130px;
}
.campAmountArea .campAmount li:last-child {
  color: #f97150;
}
.campAmountArea .campAmount li:last-child:before {
  content: ":";
  position: absolute;
  left: -15px;
}

.travelerBooker ul:not(:last-child) {
  margin-bottom: 0.8rem;
}
.travelerBooker ul li {
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.travelerBooker ul .travelerBookerInfo {
  flex: 190px;
  max-width: 190px;
}
.travelerBooker ul .travelerBookerVal:before {
  content: ":";
  position: absolute;
  left: -15px;
}

.policyAccordion .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
}
.policyAccordion .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}

.hoursGuest {
  margin: 1rem 0;
}
.hoursGuest ul li {
  font-weight: 600;
}
.hoursGuest ul li:not(:last-child) {
  margin-right: 1.25rem;
}
.hoursGuest ul li svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  font-size: 18px;
  color: #f97150;
}

.mealsBreakfast p svg {
  margin-right: 10px;
  color: #f97150;
}

.includedExcluded {
  margin-top: 1.25rem;
}
.includedExcluded ul {
  margin-top: 10px;
}
.includedExcluded ul li {
  padding-left: 30px;
  position: relative;
}
.includedExcluded ul li:not(:last-child) {
  margin-bottom: 0.8rem;
}
.includedExcluded ul li::after {
  content: "\f058";
  font-family: "Font Awesome 5 free";
  font-weight: 900;
  position: absolute;
  left: 0;
  color: #f97150;
}

.printItinerary {
  color: #000;
  font-weight: 700 !important;
  font-family: "Montserrat" !important;
}
.printItinerary svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  color: #f97150;
}
.printItinerary:hover {
  color: #f97150;
}

.blogBanner,
.contentPageBann {
  height: 300px;
  width: 100%;
  position: relative;
  background-size: cover !important;
  z-index: 1;
}
.blogBanner:after,
.contentPageBann:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.56);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.blogList {
  box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px -5px;
  transition: all ease-in-out 0.4s;
  height: 100%;
}
.blogList:hover {
  box-shadow: rgba(0, 0, 0, 0.5) 0 5px 20px -7px;
}
.blogList .eventName {
  position: absolute;
  bottom: 0;
  line-height: 1;
  background: #fff;
  padding: 1rem 1.5rem;
  display: block;
  color: #000;
  font-weight: 600;
}

.thumbnailContainer {
  position: relative;
}
.thumbnailContainer img {
  width: 100%;
  display: block;
  height: 260px;
  -o-object-fit: cover;
     object-fit: cover;
}

.postblockContent h6 {
  padding-bottom: 0;
}
.postblockContent h6 a {
  color: #333;
  font-weight: 700;
  font-family: "Montserrat";
  line-height: 1.2;
}
.postblockContent h6 a:hover {
  color: #f97150;
}
.postblockContent p {
  color: #9ca8af;
  font-family: "Montserrat";
}
.postblockContent .readMore {
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 1.25rem;
  border-bottom: #f97150 1px solid;
  padding: 5px 0;
}
.postblockContent .readMore svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  font-size: 16px;
  position: relative;
  top: -2px;
  color: #f97150;
}
.postblockContent .readMore:hover {
  color: #f97150;
}
.postblockContent .captionDate {
  color: #f97150;
  margin-bottom: 0.7rem;
  display: block;
}
.postblockContent .captionDate svg {
  margin-right: 5px;
}

.blogArea {
  padding-top: 2rem;
  padding-bottom: 7rem;
  position: relative;
}
.blogArea .blogFilter {
  position: absolute;
  right: 80px;
  top: 3.5rem;
  z-index: 1;
}

.shareThis ul li:not(:last-child) {
  margin-right: 1rem;
}
.shareThis ul li a {
  color: #5e5d5d;
  font-size: 16px;
  border: #7d7d7d 1px solid;
  height: 40px;
  width: 40px;
  line-height: 43px;
  text-align: center;
  border-radius: 50%;
  display: block;
}
.shareThis ul li a svg {
  display: inline-block;
}
.shareThis ul li a:hover {
  background: #f97150;
  color: #fff;
  border-color: #f97150;
}

.blogTitle ul {
  justify-content: center;
  margin-top: 0.6rem;
}
.blogTitle ul li {
  font-size: 1rem;
  font-weight: 600;
}
.blogTitle ul li:not(:last-child) {
  margin-right: 20px;
}
.blogTitle ul li svg {
  width: 40px;
  height: 40px;
  background: #e1e1e1;
  color: #333;
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
  padding: 10px;
  vertical-align: middle;
}

.contentPageArea {
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.rtbleft span img {
  display: block;
  width: 100%;
}
.rtbleft .bestSeller {
  position: absolute;
  left: -8px;
  top: 5px;
  background: rgba(249, 113, 81, 0.74);
  color: #fff;
  padding: 2px 10px;
  font-size: 9px;
  font-weight: 400;
}

.recommendedTourBox:not(:last-child) {
  margin-bottom: 13px;
  border-bottom: #ddd 1px solid;
  padding-bottom: 10px;
}

.travelTags {
  background: #f2f2f2;
  padding: 1rem;
  margin-top: 1rem;
}
.travelTags ul li {
  background: #fff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: #e5e5e5 1px solid;
}
.travelTags ul li:not(:last-child) {
  margin-right: 0.8rem;
}

.commentsBoxInner {
  padding: 1.25rem 2rem;
}
.commentsBoxInner:not(:last-child) {
  border-bottom: #ddd 1px solid;
}

.recentPost span {
  display: block;
}
.recentPost span img {
  width: 100%;
  display: block;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
}

.recentPost {
  padding: 1rem;
}
.recentPost:not(:last-child) {
  border-bottom: #ddd 1px solid;
}
.recentPost:hover {
  background: #f9f9f9;
}

.teamTitle {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}
.teamTitle h3 span {
  display: block;
  font-weight: 400;
}

.teamListImg {
  flex: 0 0 50%;
  max-width: 50%;
  overflow: hidden;
  height: 100%;
}
.teamListImg a {
  display: block;
  height: 100%;
}
.teamListImg a img {
  display: block;
  width: 100%;
  transform: scale(1.06);
  transition: 0.4s;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.teamListDtls {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 1rem 1rem 1rem 1.5rem;
}

.teamBox {
  box-shadow: 0 0 68px 0 rgba(0, 0, 0, 0.08);
  height: 100%;
}
.teamBox:hover .teamListImg img {
  transform: scale(1);
}
.teamBox:hover .itemSocial ul li a {
  visibility: visible;
  transform: translateY(0);
}
.teamBox:hover .itemSocial ul li a:hover {
  opacity: 1;
}
.teamBox .redMore {
  color: #f97150;
  display: inline-block;
  vertical-align: middle;
  font-family: "Montserrat";
  font-weight: 600;
}
.teamBox .redMore svg {
  vertical-align: middle;
  margin-left: 5px;
  color: #333;
}

.itemSocial {
  overflow: hidden;
  margin-bottom: 0.8rem;
}
.itemSocial ul li:not(:last-child) {
  margin-right: 0.5rem;
}
.itemSocial ul li a {
  font-size: 1.1rem;
  color: #292f69;
  opacity: 0.5;
  transform: translateY(25px);
  transition: 0.4s;
  visibility: hidden;
}

.rightPart .whyBookUs {
  box-shadow: 0px 0px 68px 0px rgba(0, 0, 0, 0.08);
}
.rightPart .whyBookUs:first-child {
  margin-top: 0;
}

.singleImg {
  position: sticky;
  top: 80px;
}
.singleImg img {
  display: block;
  width: 100%;
}

.teamIntro {
  margin-top: 5rem;
}

.parentContent h4:not(:first-child) {
  margin-top: 2rem;
}

.experience {
  margin-top: 2rem;
  border-bottom: #eee 1px solid;
  padding-bottom: 2rem;
}

.arrow {
  margin-top: 4rem;
}
.arrow img {
  max-width: 60px;
}

.personImg {
  flex: 0 0 268px;
  max-width: 268px;
}
.personImg .personImgInner {
  position: sticky;
  top: 2rem;
}
.personImg .personImgInner img {
  width: 100%;
  display: block;
}

.personDetails {
  flex: 0 0 calc(100% - 268px);
  max-width: 0 0 calc(100% - 268px);
  padding-left: 2rem;
}

.personDetailsInnerBox {
  margin-top: 2rem;
}
.personDetailsInnerBox:not(:last-child) {
  border-bottom: #eee 1px solid;
  padding-bottom: 2rem;
}
.personDetailsInnerBox ul li:not(:last-child) {
  margin-right: 1rem;
}
.personDetailsInnerBox ul li a {
  font-size: 2rem;
  color: #292f69;
}

.teamDtlsRight {
  position: sticky;
  top: 2rem;
}
.teamDtlsRight .whyBookUs:first-child {
  margin-top: 0;
}

.preload {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9500;
  background-color: #ffffff;
}

.preload .logo-container {
  position: relative;
  display: block;
  overflow: hidden;
  width: 270px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.preload .logo-container .logo {
  width: 270px;
  z-index: 9002;
  position: relative;
  display: block;
}

.preload .logo-container .logo-curtain {
  position: absolute;
  right: 1px;
  left: 1px;
  bottom: 1%;
  top: 1px;
  z-index: 9001;
  overflow: hidden;
  background-color: #eeeeec;
}

.preload .logo-container .logo-curtain div {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 100%;
  background: #f97150;
}

.rateRevMd {
  position: absolute;
  bottom: 22px;
  left: 0;
  width: 100%;
  padding: 0 2rem;
}

.rateRevMd-1 {
  position: absolute;
  bottom: 29px;
  left: 0;
  width: 100%;
  padding: 0 2.5rem 0 1.2rem;
  margin: 0 !important;
}

.batch {
  position: absolute;
  width: auto;
  background: #f97150;
  color: #fff;
  padding: 0.3rem 1rem;
  top: 5px;
  font-weight: 700;
}
.batch:before {
  content: "";
  position: absolute;
  top: 0px;
  left: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 11px 11px;
  border-color: transparent transparent #c64a2c transparent;
}
.batch:after {
  content: "";
  position: absolute;
  top: 0px;
  right: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 0 0 11px;
  border-color: transparent transparent transparent #c64a2c;
}

.noobjectFit li a img {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.ProudjectFit .MuiAvatar-root {
  background: #fff;
  padding: 5px;
  border-radius: 50%;
}
.ProudjectFit .MuiAvatar-root img {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.contactObjectfit li img {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

html {
  scroll-padding-top: 120px; /* height of your navbar */
}

.themeButton {
  background: #f97150;
  color: #fff;
  font-weight: 600;
  line-height: 44px;
  padding: 0 22px;
  border-radius: 3px;
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  box-shadow: none;
  white-space: nowrap;
}
.themeButton:hover {
  background: #d04726;
}

.missionVission .mvLeft {
  flex: 0 0 70px;
  max-width: 70px;
}
.missionVission .mvLeft span {
  display: block;
  width: 100%;
}
.missionVission .mvLeft span img {
  display: block;
  width: 100%;
}

.popularToursSlider {
  display: block;
  padding: 10px;
}
.popularToursSlider img {
  width: 100%;
  display: block;
}
.popularToursSlider.tourImgMd img {
  width: auto !important;
  display: block;
  max-height: 200px;
  margin: 0 auto;
}

.lineThrough {
  text-decoration: line-through;
}

.fromUsppBtn .ltProperties {
  margin: 0 5px;
  display: inline-block;
  vertical-align: middle;
  font-size: 80% !important;
  color: #666;
}

.ltProrerties-2 {
  font-weight: 400;
  color: #a4a2a2;
  line-height: 1;
  font-size: 85% !important;
  margin: 9px 0 0 !important;
}

.ltProrerties-3 {
  font-weight: 500 !important;
  color: #d1d1d1;
  line-height: 1;
  font-size: 85% !important;
  margin: 0 1px 0 3px !important;
}

.ptsInner {
  font-size: 12px;
}
.ptsInner li {
  white-space: nowrap !important;
  font-size: 10px;
}

.shadowPad {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 16px;
  padding: 15px;
}

.starRateMd {
  margin-top: 10px;
}

.ovrScroll {
  max-height: 120px;
  overflow-y: auto;
}
.ovrScroll h2 {
  font-size: 18px;
  padding-bottom: 5px;
}

.ovrScroll-2 {
  max-height: 130px;
  overflow-y: auto;
}
.ovrScroll-2 h2 {
  font-size: 18px;
  padding-bottom: 5px;
}

.megaMenuLeft {
  background: #fff;
  border: none !important;
  box-shadow: none !important;
  height: 100%;
  padding: 10px;
}

.megaMenuLeft nav {
  background-color: transparent;
}

.height-100 {
  height: 100px;
  overflow-y: auto;
}

.fontInter {
  font-family: "Inter" !important;
}

.notfound {
  height: 100vh;
  background: url(../../../public/images/404.jpg) no-repeat;
  text-align: center;
  background-size: cover;
}
.notfound h2 {
  font-size: 200px;
  color: #fff;
  opacity: 0.6;
  line-height: 1.2;
}
.notfound h5 {
  font-size: 45px;
  color: #fff;
}
.notfound p {
  font-size: 18px;
  color: #fff;
}
.notfound ul {
  margin-top: 30px;
}
.notfound ul li:not(:last-child) {
  margin-right: 15px;
}
.notfound ul li a {
  background: #ddd;
  font-size: 16px;
  border-radius: 30px;
  padding: 10px 30px;
  display: block;
  color: #333;
}

.ourWorkInner {
  padding: 50px 80px;
}
.ourWorkInner.ourWorkInnPage {
  padding: 50px 80px 120px;
}
.ourWorkInner .sectionTitle {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
}

.ourWorkInner {
  padding: 40px 80px;
}

.revModal .css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
  max-width: 800px;
  width: 100%;
}

.dashBoardSection {
  margin-bottom: 100px;
}

.navigationArea {
  flex: 0 0 250px;
  max-width: 250px;
  background: #fff;
  padding: 40px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 15px 0 30px -20px;
}

.dashBoardSection {
  font-family: "Montserrat";
}

.navigationBoxInner {
  margin-bottom: 1rem;
}
.navigationBoxInner ul li a {
  padding: 0.7rem 1rem;
  display: block;
  color: #6f6f6f;
  font-weight: 600;
}
.navigationBoxInner ul li a svg {
  color: #f97150;
  position: relative;
  top: 3px;
  margin-right: 0.5rem;
}
.navigationBoxInner ul li a:hover {
  background: #f3f3f3;
}
.navigationBoxInner h5 {
  padding: 0.5rem 1rem;
}

.signOutBox a {
  padding: 0.7rem 1rem;
  display: block;
  color: #333;
  background: #f3f3f3;
  font-weight: bold;
}
.signOutBox a svg {
  color: #f97150;
  position: relative;
  top: 2px;
  margin-right: 0.5rem;
}
.signOutBox a:hover {
  background: #e2e2e2;
}

.dbRight {
  flex: 1 0 0%;
  padding: 40px;
  position: relative;
}
.dbRight:after {
  content: "";
  position: absolute;
  top: 0;
  height: 180px;
  width: 100%;
  background: #fff7f5;
  z-index: -1;
  left: 0;
}

.cmnWhtSadow {
  background: #fff;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 10px 30px -10px;
}

.boxHeader {
  border-bottom: #bdb9b9 1px dashed;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.boxHeader h5 {
  padding: 0;
}
.boxHeader .boxHeaderRight a {
  color: #f97150;
  font-weight: bold;
  display: block;
}
.boxHeader .boxHeaderRight a svg {
  color: #302f2f;
  margin-right: 5px;
}

.proFileImg {
  flex: 0 0 150px;
  max-width: 100%;
}
.proFileImg span {
  display: block;
}
.proFileImg span img {
  width: 100%;
  display: block;
}

.profileDesk {
  flex: 1 0 0%;
  max-width: 100%;
  padding: 0 40px;
}
.profileDesk ul li {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;
  font-weight: 600;
}
.profileDesk ul li span {
  display: inline-block;
}
.profileDesk ul li span.value {
  font-weight: normal;
  margin-right: 10px;
}
.profileDesk ul li span.info {
  width: 90px;
}

.me-3 {
  margin-left: 15px;
}

.breadeCambs {
  margin-bottom: 30px;
}

.myProfileInner {
  max-width: 600px;
  margin: 60px auto;
}
.myProfileInner h3 {
  margin-bottom: 30px;
}

.textCenter {
  text-align: center;
}

.uploadImg {
  margin-bottom: 30px;
  flex: 0 0 250px;
  max-width: 250px;
}
.uploadImg span {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.uploadImg span img {
  width: 100%;
  display: block;
}

.myProfileRight {
  flex: 1 0 0%;
  padding-left: 40px;
}

.w-100 {
  width: 100%;
}

.boxBodyNav {
  margin-bottom: 2rem;
}
.boxBodyNav ul li {
  position: relative;
}
.boxBodyNav ul li:not(:last-child) {
  margin-right: 25px;
}
.boxBodyNav ul li:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 2px;
  background: #f97150;
  top: 50%;
  right: -14px;
}
.boxBodyNav ul li a {
  color: #302f2f;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}
.boxBodyNav ul li a:hover, .boxBodyNav ul li a.active {
  color: #f97150;
}

.accountBg {
  height: 204px;
  width: 100%;
  position: relative;
  background-size: cover !important;
  z-index: 1;
}
.accountBg:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.56);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.boxBodyTable .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none;
  border: #ddd 1px solid;
}
.boxBodyTable th {
  font-weight: 600;
  font-family: "Montserrat";
  color: #302f2f;
}
.boxBodyTable td {
  font-family: "Montserrat";
}

.NeedHelp {
  padding: 30px 10px 0;
  text-align: center;
  font-family: "Montserrat";
}
.NeedHelp ul li {
  font-size: 13px;
  font-family: "Montserrat";
}
.NeedHelp ul li:not(:last-child) {
  margin-bottom: 10px;
}
.NeedHelp ul li svg {
  color: #302f2f;
  position: relative;
  top: 2px;
  margin-right: 0.2rem;
}
.NeedHelp ul li a {
  color: #f97150;
}

.teamSkillBtm {
  max-width: 900px;
  width: 100%;
  margin: 4rem 0 6rem;
}

.gateInTouch {
  padding: 2rem 0;
  overflow-x: hidden;
}

.toggleMenu {
  display: none;
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.mobileFilterShowHideLink {
  display: none;
  font-weight: bold;
  color: #fff;
  padding: 8px 10px;
  background: #f97150;
  border-radius: 6px;
}
.mobileFilterShowHideLink .mobileFilterShowHideLink svg {
  display: inline-block;
  position: relative;
  top: 2px;
}

.aboutBanSlogan p {
  font-family: "Montserrat";
}

.aboutSecAct {
  padding: 6rem 0;
}

.popularBoxInner {
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 20px -10px;
  border-radius: 10px;
}

.loginLogo img {
  max-width: 100%;
  display: block;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.signInBdrBtn {
  border: #f97150 1px solid;
  text-align: center;
  width: 100%;
  background: #f6f6f6;
  color: #333;
  font-weight: 700;
  padding: 6px 10px;
  border-radius: 6px;
}

.reviewSendBtn ul {
  width: 100%;
  max-width: 400px;
}
.reviewSendBtn ul li {
  width: 100%;
}
.reviewSendBtn ul li:not(:last-child) {
  margin-bottom: 16px;
}
.reviewSendBtn ul li button {
  width: 100%;
}

@media only screen and (max-width: 1600px) {
  .ptbWrap {
    bottom: -39%;
  }
}
@media only screen and (max-width: 1440px) {
  .playlist .playlistLeft {
    flex: 0 0 35%;
    max-width: 35%;
  }
  .playlist .playlistRight {
    flex: 0 0 65%;
    max-width: 65%;
  }
  .ptbWrap h4 {
    font-size: 14px;
  }
  .ptbWrap .ptbInner {
    font-size: 10px;
    margin: 16px 0 16px;
  }
  .overViewArea ul li a {
    font-size: 12px;
    padding: 0 6px;
    line-height: 44px;
  }
  .overViewArea ul li a svg {
    margin-right: 2px;
    width: 12px;
  }
}
@media only screen and (max-width: 1366px) {
  .teamSkillBtm {
    max-width: 600px;
  }
  .popularToursSlider.tourImgMd img {
    max-height: 134px;
  }
  .loginLogo {
    width: 120px;
  }
  .aboutSecAct {
    padding: 0;
  }
  .gateInTouch {
    padding: 0 0;
  }
  h2 {
    font-size: 30px;
  }
  .sectionTitle span {
    font-size: 20px;
  }
  .sectionTitle h2 {
    font-size: 30px;
  }
  .filterLeftInner ul li span {
    font-size: 0.8rem;
  }
  .whyBookUs p {
    margin: 8px 0;
    font-size: 15px;
  }
  .ovrInnerBoxContent p {
    font-size: 13px;
  }
  .ovrInnerBoxContent h6 {
    font-size: 10px;
  }
  .ovrInnerBoxContent .oibcIcon {
    width: 30px !important;
  }
  .ovrInnerBoxContent .oibcIcon .oibcIconAvtr {
    width: 30px !important;
    height: 30px !important;
  }
  .ovrInnerBoxContent svg {
    width: 0.7em;
    height: 0.7em;
  }
  .oveLeftFixBox svg {
    font-size: 35px !important;
  }
  .ph-80 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .ourWorkInner {
    padding: 40px;
  }
  .destinationBox h4 {
    font-size: 27px;
  }
  .destinationBox h4 span small {
    font-size: 11px;
  }
  .destinationNumber ul li {
    font-size: 14px;
    flex: 0 0 114px;
    max-width: 114px;
  }
  .destinationNumber ul li span {
    font-size: 40px;
  }
  .popularToursBox p {
    font-size: 12px;
  }
  .dolerPp h5 {
    font-size: 16px;
  }
  .dolerPp h6 {
    padding-bottom: 2px;
    font-size: 12px;
  }
  .boxBodyNav ul li a {
    font-size: 13px;
  }
  .ptsWrap h4 a {
    font-size: 13px;
  }
  .personImg {
    flex: 0 0 180px;
    max-width: 180px;
  }
  .personDetails {
    flex: 0 0 calc(100% - 180px);
    max-width: 0 0 calc(100% - 180px);
    padding-left: 1.3rem;
  }
  .personDetails .personDetailsInner h2 {
    font-size: 22px;
  }
  .personDetails .personDetailsInner h6 {
    font-size: 18px;
  }
  .fromUsppBtn {
    font-size: 15px !important;
  }
  .missionImg .missionTop {
    top: -55px;
    width: 200px;
    height: 200px;
  }
  .missionImg .missionBtm {
    bottom: -55px;
    width: 200px;
    height: 200px;
  }
}
@media only screen and (max-width: 1350px) {
  .owl-theme.similarArtistSlider .owl-nav .owl-next {
    right: 5px;
  }
  .owl-theme.similarArtistSlider .owl-nav .owl-prev {
    left: 5px;
  }
}
@media only screen and (max-width: 1280px) {
  .ptbWrap {
    bottom: -47%;
  }
  h3 {
    font-size: 20px;
  }
  .subscribeWrap .subscribeLeft h2 {
    font-size: 32px;
  }
  .subscribeWrap .subscribeLeft h5 {
    font-size: 22px;
  }
  .ph-40 {
    padding: 0 20px;
  }
  header .topHeader .logo {
    width: 148px;
  }
  header .topHeader .topLinks ul li a {
    font-size: 12px;
    padding: 0 8px;
    line-height: 26px;
  }
  .signUpLang ul li:not(:last-child) {
    margin-right: 0.5rem;
  }
  .signUpLang ul li a {
    font-size: 12px;
  }
  .signUpLang ul li a.logInBtn {
    line-height: 36px;
    padding: 0 15px;
  }
  .signUpLang ul li a.logInBtn .svg-inline--fa {
    font-size: 14px;
  }
  .signUpLang ul li a.signUpBtn {
    line-height: 36px;
    padding: 0 15px;
  }
  .signUpLang ul li a.signUpBtn .svg-inline--fa {
    font-size: 14px;
  }
  .signUpLang ul li a img {
    width: 30px;
  }
  .signUpLang ul li.cartIcon {
    position: relative;
  }
  .signUpLang ul li.cartIcon a span {
    position: absolute;
    background: #f97150;
    color: #fff;
    font-size: 10px;
    height: 22px;
    width: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    left: 0;
    bottom: -3px;
  }
}
@media only screen and (max-width: 1204px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 1135px) {
  .missionImg .missionTop {
    right: 236px;
  }
  .missionImg .missionBtm {
    right: 236px;
  }
}
@media only screen and (max-width: 1070px) {
  .toggleMenu {
    display: block;
  }
  .toggleMenuShow {
    display: none;
  }
  .toggleMenuShow.mobileFilterShowHide {
    display: block;
    width: 190px;
    position: absolute;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px -10px;
    border-radius: 0 0 10px 10px;
    padding: 0 15px;
    left: 0;
    top: 100%;
  }
  .toggleMenuShow ul {
    display: block !important;
  }
}
@media only screen and (max-width: 1024px) {
  .missionImg {
    padding-left: 50px !important;
  }
  .ptbWrap {
    bottom: -64%;
  }
  .topRatedMusic .boxImg {
    height: 300px;
  }
  .ph-80 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ourWorkInner {
    padding: 20px;
  }
  .bestSellerLeft {
    top: 5px;
    left: 5px;
    max-width: 100px;
  }
  .oveLeftFix {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .oveLeftFix .oveLeftFixBox {
    flex: 33.3333337%;
    max-width: 33.3333337%;
    border-bottom: #ddd 1px solid !important;
  }
}
@media only screen and (max-width: 1000px) {
  .storeBoxWrap {
    margin: 0 -0.5rem;
    display: flex;
    flex-wrap: wrap;
  }
  .storeBoxWrap .storeBox {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0.5rem;
    box-shadow: none;
  }
  .storeBoxWrap .storeBox .storeBoxLeft {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .storeBoxWrap .storeBox .storeBoxRight {
    flex-basis: 100%;
    flex-wrap: wrap;
    padding: 0.8rem 0;
  }
  .storeBoxWrap .storeBox .sbPrice {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .storeBoxWrap .storeBox .sbPrice ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .storeBoxWrap .storeBox .sbPrice ul li.oldPrice {
    margin: 0 0 0 1rem;
    order: 3;
  }
}
@media only screen and (max-width: 991px) {
  .navigation .signUpLang {
    display: block;
    margin-right: 0;
  }
  .navigation .signUpLang ul {
    display: flex !important;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navigation .signUpLang ul li {
    margin-bottom: 0 !important;
    margin-right: 1rem !important;
  }
  .navigation .signUpLang ul li a,
  .navigation .signUpLang ul li .logInBtn {
    background: none;
    border: none;
    color: #fafafa;
    padding: 0 7px;
  }
  .navigation .signUpLang ul li a span,
  .navigation .signUpLang ul li .logInBtn span {
    display: none;
  }
  .navigation .signUpLang ul li a .svg-inline--fa,
  .navigation .signUpLang ul li .logInBtn .svg-inline--fa {
    font-size: 20px;
  }
  .navigation .signUpLang ul li a img,
  .navigation .signUpLang ul li .logInBtn img {
    width: 23px;
    filter: invert(100%) contrast(50);
  }
  .navigation .signUpLang ul li a.signUpBtn {
    background: none;
    border: none;
    color: #fafafa;
    padding: 0 7px;
  }
  .navigation .signUpLang ul li a.signUpBtn span {
    display: none;
  }
  .navigation .signUpLang ul li a.signUpBtn .svg-inline--fa {
    font-size: 20px;
  }
  .signUpLang {
    margin-right: 48px;
  }
  .signUpLang ul li.cartIcon a span {
    display: block !important;
    display: block !important;
    position: absolute;
    background: #eab57d;
    color: #171717;
    font-size: 10px;
    left: auto;
    bottom: 15px;
    right: 0;
  }
  .topRatedMusic .boxImg {
    height: 250px;
  }
  .humbergar {
    display: block;
  }
  .navigation {
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 400px;
    right: -100%;
    top: 0;
    z-index: 5;
    transition: all ease-in-out 0.7s;
  }
  .navigation:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    z-index: -1;
    transition: all ease-in-out 0.7s;
    transition-delay: 1s;
    transform: translateX(100%);
  }
  .navigation:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(79, 80, 80, 0.8);
    top: 0;
    z-index: -1;
    transition: all ease-in-out 0.5s;
    transition-delay: 0.5s;
    transform: translateX(150%);
    transform-origin: 100% 50% 0px;
  }
  .navigation ul {
    transition: all ease-in-out 0.7s;
    transition-delay: 1.3s;
    transform: translateX(150%);
    display: block !important;
    padding: 3rem;
  }
  .navigation.active {
    right: 0;
  }
  .navigation.active:after, .navigation.active:before {
    transform: translateX(0);
  }
  .navigation.active ul {
    transform: translateX(0);
  }
  .navClose {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    color: #fff;
    border: 2px solid #fff;
    height: 40px;
    width: 40px;
    line-height: 38px;
    text-align: center;
    border-radius: 50%;
    font-size: 1.25rem;
  }
  .navClose:hover {
    background: #fff;
    color: #f97150;
  }
  header .topHeader {
    padding: 0.3rem 0;
  }
  header .btmHeader ul li:not(:last-child) {
    margin-bottom: 1rem;
  }
  header .btmHeader ul li a {
    font-size: 1rem;
    display: block;
    line-height: 1.5 !important;
  }
  header .btmHeader ul li a:hover {
    color: #cbc394;
  }
  .forDesktop {
    display: none !important;
  }
  .midHeader {
    display: block !important;
  }
  .mbHide {
    display: none !important;
  }
  .cdInner h2 {
    font-size: 25px;
    padding-bottom: 5px;
  }
}
@media only screen and (max-width: 910px) {
  .addBanner .addBannerDesc .addBannerDesc h2 {
    font-size: 40px;
  }
  .addBanner .addBannerDesc .addBannerDesc h2 span {
    font-size: 16px;
  }
}
@media only screen and (max-width: 899px) {
  .filterLeft {
    position: absolute;
    left: -310px;
    z-index: 9999;
    top: 76px;
    width: 297px;
    border-radius: 0;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.5) 10px 0 20px -10px;
    height: calc(100% - 285px);
    transition: all ease-in-out 0.4ms;
  }
  .filterLeft h5 {
    display: none;
  }
  .filterLeft.mobileFilterShowHide {
    left: 0;
  }
  .mobileFilterShowHideLink {
    display: block;
  }
  .teamSkillBtm {
    max-width: 100%;
  }
  .teamIntro {
    max-width: 100% !important;
  }
  .ptbWrap {
    bottom: -28%;
  }
  .ptbWrap h4 {
    font-size: 18px;
  }
  .ptbWrap .ptbInner {
    font-size: 12px;
  }
  .destinationRight {
    padding: 30px 0;
  }
  .destinationRight .pattern-2 {
    left: -17px;
  }
  .aboutSlider .owl-prev,
  .aboutSlider .owl-next {
    position: static;
  }
  .bsri-1 {
    right: -22px;
  }
  .bsri-3 {
    left: -22px;
  }
  .bsrImgWrap {
    padding: 6px;
  }
  .sellerParalax {
    height: 375px;
  }
  .bestSellerDescArea {
    margin-top: -206px;
  }
  .BestSellerRoundImg {
    padding: 0;
  }
  .BestSellerRoundImg h2 {
    font-size: 27px;
    padding: 0;
  }
  .bestSellerActi {
    padding: 0 !important;
  }
  .bestSellerActi .dudiAct li {
    font-size: 16px;
  }
  .bestSellerActi .dudiAct li span {
    width: 29px;
  }
  .oldNewRate small {
    font-size: 20px;
  }
  .oldNewRate span {
    line-height: 40px;
    padding: 0 34px;
    font-size: 23px;
    margin-top: 7px;
  }
}
@media only screen and (max-width: 885px) {
  .banSrc {
    width: 90%;
  }
}
@media only screen and (max-width: 880px) {
  .missionImg .missionTop {
    display: none;
  }
  .missionImg .missionBtm {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .themeButton {
    line-height: 34px;
    font-size: 11px;
    padding: 0 15px;
  }
  .whyBookUs p {
    margin: 10px 0;
    font-size: 13px;
  }
  .blogArea .blogFilter {
    right: 0;
    top: 2rem;
  }
  h2 {
    font-size: 28px;
  }
  .homeBanner {
    height: 500px;
  }
  .ptbWrap {
    bottom: -34%;
  }
  .banner .bannSlogan h2 {
    font-size: 40px;
  }
  .topRatedMusic .boxImg {
    height: 200px;
  }
  .topRatedMusic .boxImg span {
    font-size: 17px;
  }
  .sectionTitle h2 {
    font-size: 30px;
  }
  .topRatedMusic,
  .coursesBlock {
    padding: 1rem 0;
  }
  .testimonials .testiInner .testiInnerBtm:after, .testimonials .testiInner .testiInnerBtm:before {
    font-size: 70px;
  }
  .testimonials .testiInner .testiInnerBtm:after {
    top: -23px;
  }
  .testimonials .testiInner .testiInnerBtm:before {
    content: "“";
    bottom: -23px;
  }
  .addBanner .addImg {
    width: 200px !important;
    margin: 0 auto 3rem;
  }
  .courseMenu {
    margin-bottom: 0.6rem;
  }
  .courseMenu ul li a {
    font-size: 14px;
    line-height: 30px;
    padding: 0 10px;
  }
  .Login {
    padding: 3rem 0;
  }
  .Login .regWrap {
    flex: 0 90%;
    max-width: 100%;
    margin: 0 auto;
  }
  .Login .regWrap h2 {
    font-size: 21px;
  }
  .Login .regWrap h6 {
    font-size: 10px;
  }
  .whatYouLearn h3 {
    font-size: 28px;
  }
  .courseContent h3 {
    font-size: 28px;
  }
  .requirements h3 {
    font-size: 28px;
  }
  .studentsBought h3 {
    font-size: 28px;
  }
  .boughtTogether h3 {
    font-size: 28px;
    padding-bottom: 1rem;
  }
  .Login {
    padding: 0.8rem 0;
  }
  .Login .loginWrap {
    width: 94%;
  }
  .Login .loginWrap .LoginInner {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bestSellerDescArea {
    margin-top: -160px;
  }
  .sellerParalax {
    height: 313px;
  }
}
@media only screen and (max-width: 767px) {
  .stepIconBtn span span:last-child {
    display: none !important;
  }
  h2 {
    font-size: 22px;
  }
  .SortByTop ul li {
    flex: 0 0 calc(33.3333% - 0.3rem);
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .SortByTop ul li:not(:last-child) {
    margin-right: 0.3rem;
  }
  .playListBtm ul li .playNowTableBtn {
    padding: 0 0.6rem;
  }
  .playlist .playlistLeft {
    display: none !important;
  }
  .playlist .playlistRight {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .sectionTitle h2 {
    font-size: 25px;
  }
  .fcbox .fcboxLeft {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .fcbox .fcboxRight {
    flex: 1 0;
    padding: 1rem;
  }
  .popularTopic {
    padding: 1rem 0;
  }
  .featuredCourses {
    padding: 1rem 0;
  }
  .sectionTitle.d-flex.mb-4 {
    margin-bottom: 0 !important;
  }
  .mb-4 {
    margin-bottom: 0.5rem !important;
  }
  .blog-content {
    padding-top: 0.8rem;
  }
  .blog-title {
    margin: 0 0 5px;
  }
  .listGridTop {
    margin-top: 1.5rem;
  }
  .listGridTop .listGridTopLeft h2 {
    font-size: 22px;
  }
  .projectInner .projectInnerMid p {
    font-size: 12px;
  }
  .storeSection .projectInnerMid h3 {
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }
  .storeSection {
    padding: 1rem 0;
  }
  .storeSection .storeAcordian {
    position: static;
    top: 0;
  }
  .cart-image {
    max-width: 34px;
  }
  .cart-title {
    color: #141414;
    font-size: 10px;
  }
  .cart-title a {
    color: #141414;
    font-size: 10px;
  }
  .qtyInput2 {
    border: 1px solid #e6e8ee;
    font-size: 11px;
    height: 34px;
    width: 40px;
  }
  .controls2 {
    border: 1px solid #e6e8ee;
    font-size: 20px;
    height: 34px;
    line-height: 16px;
    width: 30px;
  }
  .cart-variant,
  .cart-vendor {
    font-size: 11px;
    margin-top: 0;
  }
  .cart-item-remove {
    font-size: 11px;
    margin-top: 0;
  }
  .cart-item-product-wrap {
    margin-left: 8px;
  }
}
@media only screen and (max-width: 667px) {
  .insideSrc {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 15px;
  }
  .inside label {
    width: 100%;
    padding: 0 0 0 10px;
    text-align: left;
    min-height: 50px;
  }
  .familyDropDown ul li {
    flex: 0 0 47%;
    max-width: 47%;
  }
  .lookingFor li.radio-wrap {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ptbWrap {
    bottom: -44%;
  }
  .cdInner {
    padding: 1.5rem 0;
    color: #fff;
  }
  .cdInner .cdInnerBox {
    padding-top: 0.8rem;
  }
  .listGridTop .listGridTopLeft h2 {
    margin-bottom: 1rem;
  }
  .banner .bannSlogan h2 {
    font-size: 30px;
  }
  .newRealeaseSec {
    padding: 1.5rem 0 0;
  }
  .bestSellerDescBtm {
    padding-bottom: 1rem;
  }
}
@media only screen and (max-width: 600px) {
  .filterLeft {
    top: 50px;
  }
  .weAreMember {
    padding: 0 0 3.5rem;
  }
  .aboutSlider .owl-prev,
  .aboutSlider .owl-next {
    transform: translateY(0);
  }
  .overViewArea .ph-80 {
    padding: 0;
  }
  .sellerParalax {
    height: 245px;
  }
  .bestSellerDescArea {
    margin-top: -133px;
  }
  .cdAccordion {
    margin: 0.5rem 0;
  }
  .whatYouLearn {
    margin-bottom: 0;
  }
  .whatYouLearn h3 {
    font-size: 21px;
    padding-bottom: 0.6rem;
  }
  .courseContent h3 {
    font-size: 21px;
    padding-bottom: 0;
  }
  .cdAccordion .accordion-body {
    padding: 0 1.25rem;
  }
  .requirements h3 {
    font-size: 21px;
    padding-bottom: 0.6rem;
  }
  .description h3 {
    font-size: 21px;
  }
  .studentsBought h3 {
    font-size: 21px;
  }
  .reviews h3 {
    font-size: 21px;
  }
  .sectionTitle h2 {
    font-size: 21px;
  }
  .zoomerArea .producDetailRight h2 {
    font-size: 17px;
  }
  .boughtTogether h3 {
    font-size: 21px;
  }
  .description p:not(:last-child) {
    margin-bottom: 0.8rem;
  }
  .requirements,
  .description {
    margin-bottom: 1.25rem;
  }
  .featrueReview h3 {
    font-size: 21px;
    padding-bottom: 0.8rem;
  }
  footer .footerTop {
    padding: 1rem 0;
  }
  .blog-title {
    font-size: 16px;
    margin: 0 0 5px;
  }
  .blog-read-more {
    line-height: 34px;
    font-size: 12px;
    margin-top: 10px;
  }
  .inputArea {
    margin-top: 1rem;
  }
  .zoomerArea .producDetailRight .totalUpdate .prOffer {
    margin-top: 1rem;
  }
  .playListBtm ul li {
    position: relative;
  }
  .playListBtm ul li:first-child {
    padding-left: 55px;
  }
  .playListBtm ul li:first-child span {
    width: 34px;
    position: absolute;
    left: 15px;
    margin: 0;
    top: 6px;
  }
  button svg {
    width: 16px !important;
  }
}
@media only screen and (max-width: 599px) {
  .missionImg {
    padding: 0 !important;
    order: -1;
    margin-bottom: 10px;
  }
  .missionImg .missionMid {
    height: 248px;
    width: 100%;
  }
  .missionImg .missionMid img {
    width: 100%;
  }
  .batch {
    position: absolute;
    left: 16px;
    top: -11px;
  }
  .tourRight {
    padding: 40px 33px 38px !important;
  }
  .tourRight .height-100 {
    height: auto;
    margin-bottom: 15px;
  }
  .rateRevMd-1 {
    position: static;
    padding: 0;
    margin-top: 13px !important;
  }
}
@media only screen and (max-width: 575px) {
  .courseDtlsSection {
    padding: 1.5rem 0;
  }
  footer .col-sm-6:not(:last-child) {
    margin-bottom: 1rem;
  }
  .starArea ul li {
    font-size: 11px;
  }
  .coursesBtnArea .coursesBtn {
    padding: 5px 9px;
  }
  .subscribeWrap .subscribeLeft h2 {
    font-size: 22px;
  }
  .subscribeWrap .subscribeLeft h5 {
    font-size: 16px;
  }
  .subscribeWrap .subscribeInner .subsSrc .form-control {
    height: 48px;
    padding: 0 132px 0 20px;
  }
  .subscribeWrap .subscribeInner .subsSrc .subscribeBtn {
    font-size: 16px;
    line-height: 48px;
    width: 132px;
  }
  .destinationBox h4 {
    font-size: 16px;
  }
  .destinationBox .destinationBoxInner {
    padding: 20px 10px;
    font-size: 10px;
  }
  .destinationBox .destinationBoxInner h5 {
    font-size: 13px;
  }
}
@media only screen and (max-width: 545px) {
  .personImg {
    flex: 0 0 120px;
    max-width: 120px;
  }
  .personDetails {
    flex: 0 0 calc(100% - 120px);
    max-width: 0 0 calc(100% - 120px);
    padding-left: 1.3rem;
  }
}
@media only screen and (max-width: 480px) {
  .toggleMenu {
    font-size: 13px;
  }
  .toggleMenu .m-r-10 {
    margin-right: 5px;
  }
  .toggleMenu .m-l-15 {
    margin-left: 0px;
    position: relative;
    top: 2px;
  }
  .fromUsppBtn {
    font-size: 13px !important;
  }
  .fromUsppBtn span {
    font-size: 76% !important;
    margin-right: 2px;
  }
  .teamSkillBtm {
    margin: 1rem 0 1rem;
  }
  .teamIntro {
    margin-top: 2rem;
  }
  .popularToursBox .newSpan {
    top: 5px;
    right: 5px;
    padding: 2px 10px;
    font-size: 9px;
    border-radius: 4px;
  }
  .dudiAct li span {
    width: 10px;
    margin-right: 2px;
  }
  .popularToursBox .transBgBlack {
    height: 100%;
  }
  .ptbWrap {
    bottom: 0;
  }
  .ptbWrap .ptbInner {
    opacity: 1;
    visibility: visible;
  }
  .ptbWrap h4 {
    font-size: 14px;
    padding: 0 12px;
  }
  .ptbWrap .ptbInner {
    font-size: 10px;
    padding: 0 12px;
  }
  .dolerPp {
    padding: 10px 12px;
    opacity: 1;
    visibility: visible;
  }
  .regWrap .width-auto {
    padding: 0 2.6rem;
  }
  .comboBox .comboBoxImg {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .comboBox .comboBoxImg span img {
    border-radius: 10px;
  }
  .comboBox .comboBoxDesc {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .reviews .reviewBoxIcon {
    flex: 0 0 60px;
    max-width: 60px;
  }
  .reviews .reviewBoxIcon span {
    font-size: 12px;
    width: 44px;
    height: 44px;
    line-height: 44px;
  }
  .addBuy {
    margin-top: 2.25rem;
  }
  .addBuy ul li a,
  .addBuy ul li .buyNowShop {
    font-size: 14px;
    padding: 0 1.5rem;
  }
  .ph-80 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .destinationsection .css-v7dq2t-MuiGrid2-root {
    padding: 0;
  }
  .ourWorkInner {
    padding: 20px 5px;
  }
  .popularTours .css-fov7qg-MuiGrid2-root {
    padding: 0 10px;
  }
  .popularTours {
    padding-top: 0.5rem;
    padding-bottom: 0.3rem;
  }
  h2 {
    font-size: 23px !important;
  }
  .ourWorkBox span {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  .ourWorkBox span img {
    width: 30px;
  }
  .ourWorkBox h3 {
    font-size: 0.8rem;
  }
  .destinationNumber {
    margin: 20px 0;
  }
  .destinationNumber ul li {
    font-size: 11px;
  }
  .destinationNumber ul li span {
    font-size: 25px;
  }
  .destinationRight h2 {
    font-size: 26px;
  }
  .destinationRight h5 {
    font-size: 18px;
  }
  .acordianSrc .acordianSrcInput {
    height: 42px;
    padding: 0 20px;
    font-size: 13px;
  }
}
@media only screen and (max-width: 450px) {
  .personImg {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .personDetails {
    flex: 0 0 100%;
    max-width: 0 0 100%;
    padding-left: 0;
  }
}
@media only screen and (max-width: 414px) {
  .bestSellerDescArea {
    margin-top: -107px;
  }
  .sellerParalax {
    height: 221px;
  }
  .mainFooter p,
  .mainFooter a {
    font-size: 12px;
  }
  .ptbWrap h4 {
    font-size: 11px;
  }
  .ptbWrap .ptbInner {
    margin: 5px 0 8px;
  }
  .ptbWrap .enquiry {
    margin: 8px 0 0 15px;
    font-size: 11px;
  }
  .dolerPp {
    padding: 4px 12px;
  }
  .dolerPp h5 {
    font-size: 12px;
  }
  .dolerPp h6 {
    font-size: 9px;
  }
}/*# sourceMappingURL=style.css.map */