@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}
@media only screen and (max-width: 1600px) {
  .ptbWrap {
    bottom: -39%;
  }
}

@media only screen and (max-width: 1440px) {
  .playlist {
    .playlistLeft {
      flex: 0 0 35%;
      max-width: 35%;
    }
  }

  .playlist {
    .playlistRight {
      flex: 0 0 65%;
      max-width: 65%;
    }
  }
  .ptbWrap {
    h4 {
      font-size: 14px;
    }
    .ptbInner {
      font-size: 10px;
      margin: 16px 0 16px;
    }
  }
  .overViewArea {
    ul {
      li {
        a {
          font-size: 12px;
          padding: 0 6px;
          line-height: 44px;
          svg {
            margin-right: 2px;
            width: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .teamSkillBtm {
    max-width: 600px;
 } 
  .popularToursSlider {
    &.tourImgMd {
      img {
        max-height: 134px;
    }
    }
  }
  .loginLogo {
    width: 120px;
  }
  .aboutSecAct {
    padding: 0;
  }
  .gateInTouch {
    padding: 0 0;
  }
  h2 {
    font-size: 30px;
  }
  .sectionTitle {
    span {
      font-size: 20px;
    }
    h2 {
      font-size: 30px;
    }
  }
  .filterLeftInner {
    ul {
      li {
        span {
          font-size: 0.8rem;
        }
      }
    }
  }
  .whyBookUs {
    p {
      margin: 8px 0;
      font-size: 15px;
    }
  }
  .ovrInnerBoxContent {
    p {
      font-size: 13px;
    }
    h6 {
      font-size: 10px;
    }
    .oibcIcon {
      width: 30px !important;
      .oibcIconAvtr {
        width: 30px !important;
        height: 30px !important;
      }
    }
    svg {
      width: 0.7em;
      height: 0.7em;
    }
  }
  .oveLeftFixBox {
    svg {
      font-size: 35px !important;
    }
  }
  .ph-80 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .ourWorkInner {
    padding: 40px;
  }
  .destinationBox {
    h4 {
      font-size: 27px;
      span {
        small {
          font-size: 11px;
        }
      }
    }
  }
  .destinationNumber {
    ul {
      li {
        font-size: 14px;
        flex: 0 0 114px;
        max-width: 114px;
        span {
          font-size: 40px;
        }
      }
    }
  }
  .popularToursBox {
    p {
      font-size: 12px;
    }
  }
  .dolerPp {
    h5 {
      font-size: 16px;
    }
    h6 {
      padding-bottom: 2px;
      font-size: 12px;
    }
  }
  .boxBodyNav {
    ul {
      li {
        a {
          font-size: 13px;
        }
      }
    }
  }
  .ptsWrap {
    h4 {
      a {
        font-size: 13px;
      }
    }
  }
  .personImg {
    flex: 0 0 180px;
    max-width: 180px;
  }
  .personDetails {
    flex: 0 0 calc(100% - 180px);
    max-width: 0 0 calc(100% - 180px);
    padding-left: 1.3rem;
    .personDetailsInner {
      h2 {
        font-size: 22px;
      }
      h6 {
        font-size: 18px;
      }
    }
  }

  .fromUsppBtn {
    font-size: 15px !important;
  }

  .missionImg {
    .missionTop {
      top: -55px;
      width: 200px;
      height: 200px;
    }
    .missionBtm {
      bottom: -55px;
      width: 200px;
      height: 200px;
    }
  }
}

@media only screen and (max-width: 1350px) {
  .owl-theme.similarArtistSlider {
    .owl-nav {
      .owl-next {
        right: 5px;
      }

      .owl-prev {
        left: 5px;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .ptbWrap {
    bottom: -47%;
  }
  h3 {
    font-size: 20px;
  }
  .subscribeWrap {
    .subscribeLeft {
      h2 {
        font-size: 32px;
      }
    }
  }

  .subscribeWrap {
    .subscribeLeft {
      h5 {
        font-size: 22px;
      }
    }
  }

  .ph-40 {
    padding: 0 20px;
  }

  header {
    .topHeader {
      .logo {
        width: 148px;
      }

      .topLinks {
        ul {
          li {
            a {
              font-size: 12px;
              padding: 0 8px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }

  .signUpLang {
    ul {
      li {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }

        a {
          font-size: 12px;

          &.logInBtn {
            line-height: 36px;
            padding: 0 15px;

            .svg-inline--fa {
              font-size: 14px;
            }
          }

          &.signUpBtn {
            line-height: 36px;
            padding: 0 15px;

            .svg-inline--fa {
              font-size: 14px;
            }
          }

          img {
            width: 30px;
          }
        }

        &.cartIcon {
          position: relative;

          a {
            span {
              position: absolute;
              background: $bg-1;
              color: $bg-white;
              font-size: 10px;
              height: 22px;
              width: 22px;
              line-height: 22px;
              text-align: center;
              border-radius: 50%;
              left: 0;
              bottom: -3px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1204px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 1135px) {
  .missionImg {
    .missionTop {
      right: 236px;
    }
    .missionBtm {
      right: 236px;
    }
  }
}

@media only screen and (max-width: 1070px) {
  .toggleMenu {
    display: block;
  }
  .toggleMenuShow {
    display: none;
  }
  .toggleMenuShow.mobileFilterShowHide {
    display: block;
    width: 190px;
    position: absolute;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px -10px;
    border-radius: 0 0 10px 10px;
    padding: 0 15px;
    left: 0;
    top: 100%;
  }

  .toggleMenuShow {
    ul {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .missionImg {
    padding-left: 50px !important;
  }
  .ptbWrap {
    bottom: -64%;
  }
  .topRatedMusic {
    .boxImg {
      height: 300px;
    }
  }
  .ph-80 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ourWorkInner {
    padding: 20px;
  }
  .bestSellerLeft {
    top: 5px;
    left: 5px;
    max-width: 100px;
  }

  .oveLeftFix {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    .oveLeftFixBox {
      flex: 33.3333337%;
      max-width: 33.3333337%;
      border-bottom: #ddd 1px solid !important;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .storeBoxWrap {
    margin: 0 -0.5rem;
    display: flex;
    flex-wrap: wrap;

    .storeBox {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 0.5rem;
      box-shadow: none;

      .storeBoxLeft {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .storeBoxRight {
        flex-basis: 100%;
        flex-wrap: wrap;
        padding: 0.8rem 0;
      }

      .sbPrice {
        flex: 0 0 100%;
        max-width: 100%;

        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;

          li.oldPrice {
            margin: 0 0 0 1rem;
            order: 3;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .navigation {
    .signUpLang {
      display: block;
      margin-right: 0;
      ul {
        display: flex !important;
        padding-top: 0;
        padding-bottom: 0;
        li {
          margin-bottom: 0 !important;
          margin-right: 1rem !important;
          a,
          .logInBtn {
            background: none;
            border: none;
            color: #fafafa;
            padding: 0 7px;
            span {
              display: none;
            }
            .svg-inline--fa {
              font-size: 20px;
            }
            img {
              width: 23px;
              filter: invert(100%) contrast(50);
            }
          }
          a.signUpBtn {
            background: none;
            border: none;
            color: #fafafa;
            padding: 0 7px;
            span {
              display: none;
            }
            .svg-inline--fa {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .signUpLang {
    margin-right: 48px;
    ul {
      li.cartIcon {
        a {
          span {
            display: block !important;
            display: block !important;
            position: absolute;
            background: #eab57d;
            color: #171717;
            font-size: 10px;
            left: auto;
            bottom: 15px;
            right: 0;
          }
        }
      }
    }
  }
  .topRatedMusic {
    .boxImg {
      height: 250px;
    }
  }

  .humbergar {
    display: block;
  }

  .navigation {
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 400px;
    right: -100%;
    top: 0;
    z-index: 5;
    transition: all ease-in-out 0.7s;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.8);
      top: 0;
      z-index: -1;
      transition: all ease-in-out 0.7s;
      transition-delay: 1s;
      transform: translateX(100%);
    }

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(79, 80, 80, 0.8);
      top: 0;
      z-index: -1;
      transition: all ease-in-out 0.5s;
      transition-delay: 0.5s;
      transform: translateX(150%);
      transform-origin: 100% 50% 0px;
    }

    ul {
      transition: all ease-in-out 0.7s;
      transition-delay: 1.3s;
      transform: translateX(150%);
      display: block !important;
      padding: 3rem;
    }

    &.active {
      right: 0;

      &:after,
      &:before {
        transform: translateX(0);
      }

      ul {
        transform: translateX(0);
      }
    }
  }

  .navClose {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    color: #fff;
    border: 2px solid #fff;
    height: 40px;
    width: 40px;
    line-height: 38px;
    text-align: center;
    border-radius: 50%;
    font-size: 1.25rem;

    &:hover {
      background: #fff;
      color: $bg-1;
    }
  }

  header {
    .topHeader {
      padding: 0.3rem 0;
    }

    .btmHeader {
      ul {
        li {
          &:not(:last-child) {
            margin-bottom: 1rem;
          }

          a {
            font-size: 1rem;
            display: block;
            line-height: 1.5 !important;

            &:hover {
              color: #cbc394;
            }
          }
        }
      }
    }
  }

  .forDesktop {
    display: none !important;
  }

  .midHeader {
    display: block !important;
  }
  .mbHide {
    display: none !important;
  }

  .cdInner {
    h2 {
      font-size: 25px;
      padding-bottom: 5px;
    }
  }
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
  .addBanner {
    .addBannerDesc {
      .addBannerDesc {
        h2 {
          font-size: 40px;

          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 899px) {
  .filterLeft {
    position: absolute;
    left: -310px;
    z-index: 9999;
    top: 76px;
    width: 297px;
    border-radius: 0;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.5) 10px 0 20px -10px;
    height: calc(100% - 285px);
    transition: all ease-in-out 0.4ms;
    h5 {
      display: none;
    }
  }
  .filterLeft.mobileFilterShowHide {
    left: 0;
  }

  .mobileFilterShowHideLink {
    display: block;
  }
  .teamSkillBtm {
    max-width: 100%;
  }
  .teamIntro {
    max-width: 100% !important;
  }
  .ptbWrap {
    bottom: -28%;
    h4 {
      font-size: 18px;
    }
    .ptbInner {
      font-size: 12px;
    }
  }
  .destinationRight {
    padding: 30px 0;
    .pattern-2 {
      left: -17px;
    }
  }
  .aboutSlider {
    .owl-prev,
    .owl-next {
      position: static;
    }
  }
  .bsri-1 {
    right: -22px;
  }
  .bsri-3 {
    left: -22px;
  }
  .bsrImgWrap {
    padding: 6px;
  }
  .sellerParalax {
    height: 375px;
  }
  .bestSellerDescArea {
    margin-top: -206px;
  }
  .BestSellerRoundImg {
    padding: 0;
    h2 {
      font-size: 27px;
      padding: 0;
    }
  }
  .bestSellerActi {
    padding: 0 !important;
    .dudiAct {
      li {
        font-size: 16px;
        span {
          width: 29px;
        }
      }
    }
  }
  .oldNewRate {
    small {
      font-size: 20px;
    }
    span {
      line-height: 40px;
      padding: 0 34px;
      font-size: 23px;
      margin-top: 7px;
    }
  }
}

@media only screen and (max-width: 885px) {
  .banSrc {
    width: 90%;
  }
}

@media only screen and (max-width: 880px) {
  .missionImg {
    .missionTop {
      display: none;
    }
    .missionBtm {
      display: none;
    }
  }
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
  .themeButton {
    line-height: 34px;
    font-size: 11px;
    padding: 0 15px;
  }
  .whyBookUs {
    p {
      margin: 10px 0;
      font-size: 13px;
    }
  }
  .blogArea {
    .blogFilter {
      right: 0;
      top: 2rem;
    }
  }
  h2 {
    font-size: 28px;
  }
  .homeBanner {
    height: 500px;
  }
  .ptbWrap {
    bottom: -34%;
  }
  .banner {
    .bannSlogan {
      h2 {
        font-size: 40px;
      }
    }
  }

  .topRatedMusic {
    .boxImg {
      height: 200px;

      span {
        font-size: 17px;
      }
    }
  }

  .sectionTitle {
    h2 {
      font-size: 30px;
    }
  }

  .topRatedMusic,
  .coursesBlock {
    padding: 1rem 0;
  }

  .testimonials {
    .testiInner {
      .testiInnerBtm {
        &:after,
        &:before {
          font-size: 70px;
        }

        &:after {
          top: -23px;
        }

        &:before {
          content: "“";
          bottom: -23px;
        }
      }
    }
  }

  .addBanner {
    .addImg {
      width: 200px !important;
      margin: 0 auto 3rem;
    }
  }

  .courseMenu {
    margin-bottom: 0.6rem;

    ul {
      li {
        a {
          font-size: 14px;
          line-height: 30px;
          padding: 0 10px;
        }
      }
    }
  }

  .Login {
    padding: 3rem 0;

    .regWrap {
      flex: 0 90%;
      max-width: 100%;
      margin: 0 auto;

      h2 {
        font-size: 21px;
      }

      h6 {
        font-size: 10px;
      }
    }
  }

  .whatYouLearn {
    h3 {
      font-size: 28px;
    }
  }

  .courseContent {
    h3 {
      font-size: 28px;
    }
  }

  .requirements {
    h3 {
      font-size: 28px;
    }
  }

  .studentsBought {
    h3 {
      font-size: 28px;
    }
  }

  .boughtTogether {
    h3 {
      font-size: 28px;
      padding-bottom: 1rem;
    }
  }
  .Login {
    padding: 0.8rem 0;
    .loginWrap {
      width: 94%;
      .LoginInner {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .bestSellerDescArea {
    margin-top: -160px;
  }
  .sellerParalax {
    height: 313px;
  }
}

@media only screen and (max-width: 767px) {
  .stepIconBtn {
    span {
      span:last-child{
        display: none !important
    }
    }
  }
  h2 {
    font-size: 22px;
  }
  .SortByTop {
    ul {
      li {
        flex: 0 0 calc(33.3333% - 0.3rem);
        margin-top: 3px;
        margin-bottom: 3px;
        &:not(:last-child) {
          margin-right: 0.3rem;
        }
      }
    }
  }
  .playListBtm {
    ul {
      li {
        .playNowTableBtn {
          padding: 0 0.6rem;
        }
      }
    }
  }

  .playlist .playlistLeft {
    display: none !important;
  }

  .playlist .playlistRight {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sectionTitle {
    h2 {
      font-size: 25px;
    }
  }

  .fcbox {
    .fcboxLeft {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .fcbox {
    .fcboxRight {
      flex: 1 0;
      padding: 1rem;
    }
  }

  .popularTopic {
    padding: 1rem 0;
  }

  .featuredCourses {
    padding: 1rem 0;
  }

  .sectionTitle.d-flex.mb-4 {
    margin-bottom: 0 !important;
  }

  .mb-4 {
    margin-bottom: 0.5rem !important;
  }

  .blog-content {
    padding-top: 0.8rem;
  }

  .blog-title {
    margin: 0 0 5px;
  }

  .listGridTop {
    margin-top: 1.5rem;

    .listGridTopLeft {
      h2 {
        font-size: 22px;
      }
    }
  }

  .projectInner {
    .projectInnerMid {
      p {
        font-size: 12px;
      }
    }
  }

  .storeSection {
    .projectInnerMid {
      h3 {
        font-size: 1rem;
        padding-bottom: 0.5rem;
      }
    }
  }

  .storeSection {
    padding: 1rem 0;

    .storeAcordian {
      position: static;
      top: 0;
    }
  }
  .cart-image {
    max-width: 34px;
  }
  .cart-title {
    color: #141414;
    font-size: 10px;
    a {
      color: #141414;
      font-size: 10px;
    }
  }
  .qtyInput2 {
    border: 1px solid #e6e8ee;
    font-size: 11px;
    height: 34px;
    width: 40px;
  }
  .controls2 {
    border: 1px solid #e6e8ee;
    font-size: 20px;
    height: 34px;
    line-height: 16px;
    width: 30px;
  }
  .cart-variant,
  .cart-vendor {
    font-size: 11px;
    margin-top: 0;
  }
  .cart-item-remove {
    font-size: 11px;
    margin-top: 0;
  }
  .cart-item-product-wrap {
    margin-left: 8px;
  }
}

@media only screen and (max-width: 736px) {
}

@media only screen and (max-width: 667px) {
  .insideSrc {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 15px;
  }
  .inside {
    label {
      width: 100%;
      padding: 0 0 0 10px;
      text-align: left;
      min-height: 50px;
    }
  }
  .familyDropDown {
    ul {
      li {
        flex: 0 0 47%;
        max-width: 47%;
    }
    }
  }
  .lookingFor {
    li.radio-wrap {
      flex: 0 0 100%;
      max-width: 100%;
  }
  }
  .ptbWrap {
    bottom: -44%;
  }

  .cdInner {
    padding: 1.5rem 0;
    color: #fff;
    .cdInnerBox {
      padding-top: 0.8rem;
    }
  }
  .listGridTop {
    .listGridTopLeft {
      h2 {
        margin-bottom: 1rem;
      }
    }
  }

  .banner {
    .bannSlogan {
      h2 {
        font-size: 30px;
      }
    }
  }

  .newRealeaseSec {
    padding: 1.5rem 0 0;
  }
  .bestSellerDescBtm {
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .filterLeft {
    top: 50px;
}
  .weAreMember {
    padding: 0 0 3.5rem;
  }
  .aboutSlider .owl-prev,
  .aboutSlider .owl-next {
    transform: translateY(calc(0 - 0));
  }
  .overViewArea .ph-80 {
    padding: 0;
  }
  .sellerParalax {
    height: 245px;
  }
  .bestSellerDescArea {
    margin-top: -133px;
  }
  .cdAccordion {
    margin: 0.5rem 0;
  }
  .whatYouLearn {
    margin-bottom: 0;

    h3 {
      font-size: 21px;
      padding-bottom: 0.6rem;
    }
  }

  .courseContent {
    h3 {
      font-size: 21px;
      padding-bottom: 0;
    }
  }
  .cdAccordion {
    .accordion-body {
      padding: 0 1.25rem;
    }
  }
  .requirements {
    h3 {
      font-size: 21px;
      padding-bottom: 0.6rem;
    }
  }

  .description h3 {
    font-size: 21px;
  }

  .studentsBought {
    h3 {
      font-size: 21px;
    }
  }

  .reviews {
    h3 {
      font-size: 21px;
    }
  }

  .sectionTitle {
    h2 {
      font-size: 21px;
    }
  }

  .zoomerArea {
    .producDetailRight {
      h2 {
        font-size: 17px;
      }
    }
  }

  .boughtTogether {
    h3 {
      font-size: 21px;
    }
  }

  .description {
    p {
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }
  }

  .requirements,
  .description {
    margin-bottom: 1.25rem;
  }

  .featrueReview {
    h3 {
      font-size: 21px;
      padding-bottom: 0.8rem;
    }
  }

  footer {
    .footerTop {
      padding: 1rem 0;
    }
  }

  .blog-title {
    font-size: 16px;
    margin: 0 0 5px;
  }

  .blog-read-more {
    line-height: 34px;
    font-size: 12px;
    margin-top: 10px;
  }

  .inputArea {
    margin-top: 1rem;
  }

  .zoomerArea {
    .producDetailRight {
      .totalUpdate {
        .prOffer {
          margin-top: 1rem;
        }
      }
    }
  }
  .playListBtm {
    ul {
      li {
        position: relative;
        &:first-child {
          padding-left: 55px;
          span {
            width: 34px;
            position: absolute;
            left: 15px;
            margin: 0;
            top: 6px;
          }
        }
      }
    }
  }
  button svg {
    width: 16px !important;
  }
  // button {
  //   font-size: 10px !important;
  //   padding: 5px 15px !important;
  // }
}
@media only screen and (max-width: 599px) {
  .missionImg {
    padding: 0 !important;
    order: -1;
    margin-bottom: 10px;
    .missionMid {
      height: 248px;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .batch {
    position: absolute;
    left: 16px;
    top: -11px;
  }
  .tourRight {
    padding: 40px 33px 38px !important;
    .height-100 {
      height: auto;
      margin-bottom: 15px;
    }
  }
  .rateRevMd-1 {
    position: static;
    padding: 0;
    margin-top: 13px !important;
  }
}
@media only screen and (max-width: 575px) {
  .courseDtlsSection {
    padding: 1.5rem 0;
  }

  footer {
    .col-sm-6 {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  .starArea {
    ul {
      li {
        font-size: 11px;
      }
    }
  }

  .coursesBtnArea {
    .coursesBtn {
      padding: 5px 9px;
    }
  }

  .subscribeWrap {
    .subscribeLeft {
      h2 {
        font-size: 22px;
      }

      h5 {
        font-size: 16px;
      }
    }
  }

  .subscribeWrap {
    .subscribeInner {
      .subsSrc {
        .form-control {
          height: 48px;
          padding: 0 132px 0 20px;
        }

        .subscribeBtn {
          font-size: 16px;
          line-height: 48px;
          width: 132px;
        }
      }
    }
  }
  .destinationBox {
    h4 {
      font-size: 16px;
    }
    .destinationBoxInner {
      padding: 20px 10px;
      font-size: 10px;
      h5 {
        font-size: 13px;
      }
    }
  }
}

@media only screen and (max-width: 545px) {
  .personImg {
    flex: 0 0 120px;
    max-width: 120px;
  }
  .personDetails {
    flex: 0 0 calc(100% - 120px);
    max-width: 0 0 calc(100% - 120px);
    padding-left: 1.3rem;
  }
}

@media only screen and (max-width: 480px) {
  .toggleMenu {
    font-size: 13px;
    .m-r-10 {
      margin-right: 5px;
    }
    .m-l-15 {
      margin-left: 0px;
      position: relative;
      top: 2px;
    }
  }
  .fromUsppBtn {
    font-size: 13px !important;
    span {
      font-size: 76% !important;
      margin-right: 2px;
    }
  }
  .teamSkillBtm {
    margin: 1rem 0 1rem;
  }
  .teamIntro {
    margin-top: 2rem;
  }
  .popularToursBox {
    .newSpan {
      top: 5px;
      right: 5px;
      padding: 2px 10px;
      font-size: 9px;
      border-radius: 4px;
    }
  }
  .dudiAct {
    li {
      span {
        width: 10px;
        margin-right: 2px;
      }
    }
  }
  .popularToursBox {
    .transBgBlack {
      height: 100%;
    }
  }
  .ptbWrap {
    bottom: 0;
    .ptbInner {
      opacity: 1;
      visibility: visible;
    }
    h4 {
      font-size: 14px;
      padding: 0 12px;
    }
    .ptbInner {
      font-size: 10px;
      padding: 0 12px;
    }
  }
  .dolerPp {
    padding: 10px 12px;
    opacity: 1;
    visibility: visible;
  }
  .regWrap {
    .width-auto {
      padding: 0 2.6rem;
    }
  }
  .comboBox {
    .comboBoxImg {
      flex: 0 0 100%;
      max-width: 100%;

      span {
        img {
          border-radius: 10px;
        }
      }
    }

    .comboBoxDesc {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .reviews {
    .reviewBoxIcon {
      flex: 0 0 60px;
      max-width: 60px;

      span {
        font-size: 12px;
        width: 44px;
        height: 44px;
        line-height: 44px;
      }
    }
  }

  .addBuy {
    margin-top: 2.25rem;

    ul {
      li {
        a,
        .buyNowShop {
          font-size: 14px;
          padding: 0 1.5rem;
        }
      }
    }
  }
  .ph-80 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .destinationsection {
    .css-v7dq2t-MuiGrid2-root {
      padding: 0;
    }
  }
  .ourWorkInner {
    padding: 20px 5px;
  }
  .popularTours {
    .css-fov7qg-MuiGrid2-root {
      padding: 0 10px;
    }
  }
  .popularTours {
    padding-top: 0.5rem;
    padding-bottom: 0.3rem;
  }
  h2 {
    font-size: 23px !important;
  }
  .ourWorkBox {
    span {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;
      img {
        width: 30px;
      }
    }
    h3 {
      font-size: 0.8rem;
    }
  }
  .destinationNumber {
    margin: 20px 0;
    ul {
      li {
        font-size: 11px;
        span {
          font-size: 25px;
        }
      }
    }
  }
  .destinationRight {
    h2 {
      font-size: 26px;
    }
    h5 {
      font-size: 18px;
    }
  }
  .acordianSrc {
    .acordianSrcInput {
      height: 42px;
      padding: 0 20px;
      font-size: 13px;
    }
  }
}
@media only screen and (max-width: 450px) {
  .personImg {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .personDetails {
    flex: 0 0 100%;
    max-width: 0 0 100%;
    padding-left: 0;
  }
}
@media only screen and (max-width: 414px) {
  .bestSellerDescArea {
      margin-top: -107px;
  }
  .sellerParalax {
    height: 221px;
  }
  .mainFooter p,
  .mainFooter a {
    font-size: 12px;
  }
  .ptbWrap {
    h4 {
      font-size: 11px;
    }
    .ptbInner {
      margin: 5px 0 8px;
    }
    .enquiry {
      margin: 8px 0 0 15px;
      font-size: 11px;
    }
  }
  .dolerPp {
    padding: 4px 12px;
    h5 {
      font-size: 12px;
    }
    h6 {
      font-size: 9px;
    }
  }
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
