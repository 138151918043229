@import 'variable';
@import 'shortcode';
@import 'form';
@import 'header';
@import 'footer';
@import 'mixin';
@import 'banner';
.destinationsection {
    position: relative;
    z-index: 1;
    padding-bottom: 7rem;
}
.destinationBox {
    overflow: hidden;
    border-radius: 16px !important;
    text-align: center;
    display: block;
    color: $bg-white !important;
    &:after {
        content: '';
        position: absolute;
        opacity: 0.6;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: top;
        transform: scaleY(0);
        transition: transform 0.4s ease-in-out;
        background: #d248fa;
        background: -moz-linear-gradient(-45deg, #d248fa 0%, #fb5d36 100%);
        background: -webkit-linear-gradient(-45deg, #d248fa 0%, #fb5d36 100%);
        background: linear-gradient(135deg, #d248fa 0%, #fb5d36 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d248fa', endColorstr='#fb5d36',GradientType=1 );
    }
    h4 {
        color: #fff;
        text-transform: uppercase;
        transition: all ease-in-out 0.4s;
        font-weight: 700;
        font-size: 32px;
        position: relative;
        -webkit-transform: translateY(75%);
        transform: translateY(75%);
        span {
            display: flex;
            font-weight: 600;
            line-height: 1;
            justify-content: center;
            small {
                border: #fff 1px solid;
                border-radius: 30px;
                padding: 5px 15px;
                font-size: 15px;
            }
        }
    }
    img {
        width: 100%;
        display: block;
        transition: opacity 0.7s, transform 0.7s;
    }
    .destinationBoxInner * {
        -webkit-box-sizing: padding-box;
        box-sizing: padding-box;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }
    .destinationBoxInner {
        padding: 20px;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        .dbiHide {
            opacity: 0;
            visibility: hidden;
            transition: all ease-in-out 0.4s;
        }
        h5 {
            color: $bg-white;
            font-size: 30px;
            font-weight: 600;
        }
    }
    &:hover {
        img {
            opacity: 0.2;
            filter: blur(5px);
            transform: scale(1.1);
        }
        &:after {
            transform-origin: bottom;
            transform: scaleY(1);
        }
        .dbiHide {
            opacity: 1;
            visibility: visible;
            -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        h4 {
            opacity: 1;
            border-color: rgba(255, 255, 255, 0.5);
            -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    }
}

.destinationRight {
    .LearnMoreBtn {
        font-family: 'Montserrat';
        font-weight: 800 !important;
        box-shadow: none;
    }
    .pattern-1 {
        position: absolute;
        top: -50px;
        right: 150px;
        z-index: -1;
    }
    .pattern-2 {
        position: absolute;
        top: 50%;
        left: -40px;
        transform: translateY(-50%);
        z-index: -1;
    }
    .pattern-3 {
        position: absolute;
        bottom: -50px;
        right: 150px;
        z-index: -1;
    }
}

.mt-L20 {
    margin-top: -20px;
}

.destinationRight {
    padding: 30px 60px;
    h5 {
        font-family: 'Amertha PERSONAL USE ONLY';
        color: $bg-1;
        font-size: 30px;
        padding: 0;
    }
    h2 {
        font-size: 40px;
        color: $bg-2;
    }
    p {
        color: $bg-2;
    }
}
.destinationNumber {
    margin: 60px 0;
    ul {
        li {
            font-family: 'Montserrat';
            font-size: 16px;
            font-weight: 600;
            color: #444444;
            flex: 0 0 130px;
            max-width: 130px;
            text-transform: uppercase;
            span {
                display: block;
                font-size: 46px;
                color: $bg-1;
                font-family: 'Inter';
                font-weight: bold;
            }
        }
    }
}

.popularToursBox {
    display: block;
    border-radius: 10px !important;
    overflow: hidden !important;
    position: relative;
    img {
        display: block;
        width: 100%;
    }
    .newSpan {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 10px 15px;
        z-index: 1;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        border-radius: 6px;
        // &:after {
        //     content: '';
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -65%);
        //     width: 100px;
        //     height: 100px;
        //     border-radius: 50%;
        //     z-index: -1;
        // }
        &.bgParple {
                background: #92278f;
        }
        &.bgGreen {
                background: #39b54a;
        }
    }

    .transBgBlack {
        content: '';
        position: absolute;
        bottom: 0;
        height: 25%;
        width: 100%;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0.8) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
        transition: all ease-in-out 0.6s;
    }
    &:hover {
        .ptbWrap {
            bottom: 0;
            .ptbInner,
            .dolerPp {
                opacity: 1;
                visibility: visible;
            }
        }
        .transBgBlack {
            height: 100%;
        }
    }
}
.ptbWrap {
    position: absolute;
    bottom: -30%;
    width: 100%;
    left: 0;
    z-index: 1;
    color: #fff;
    transition: all ease-in-out 0.6s;
    padding: 20px 0;

    h4 {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        padding: 0 20px;
    }
    .ptbInner {
        padding: 0 20px;
        opacity: 0;
        visibility: hidden;
        margin: 10px 0 20px;
        transition: all ease-in-out 0.6s;
        font-size: 12px;
        ul {
            margin-top: 10px;
            li{
                font-weight: 600;
            }
        }
    }
    .enquiry {
        margin: 20px 0 0 20px;
        font-size: 14px;
        color: #fff;
        font-family: 'Montserrat';
        font-weight: 700;
        svg {
            font-size: 13px;
            line-height: 1;
            position: relative;
            top: 2px;
        }
    }
}

.dolerPp {
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 0.8s;
    padding: 10px 20px;
    background: -moz-linear-gradient(
        left,
        rgba(251, 93, 54, 1) 46%,
        rgba(251, 93, 54, 0.64) 60%,
        rgba(94, 35, 20, 0) 85%,
        rgba(0, 0, 0, 0) 100%
    );
    background: -webkit-linear-gradient(
        left,
        rgba(251, 93, 54, 1) 46%,
        rgba(251, 93, 54, 0.64) 60%,
        rgba(94, 35, 20, 0) 85%,
        rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
        to right,
        rgba(251, 93, 54, 1) 46%,
        rgba(251, 93, 54, 0.64) 60%,
        rgba(94, 35, 20, 0) 85%,
        rgba(0, 0, 0, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb5d36', endColorstr='#00000000',GradientType=1 );

    span{
        text-decoration: line-through;
    }
    h6 {
        padding-bottom: 5px;
        color: #fff;
        
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        span {
            font-weight: 600;
        }
    }
    h5 {
        padding-bottom: 0;
        color: #fff;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        sup {
            font-size: 14px;
        }
    }
}
.popularTours {
    padding-top: 2.5rem;
    padding-bottom: 3rem;
}
.sectionTitle {
    h5 {
        font-family: 'Amertha PERSONAL USE ONLY';
        color: $bg-1;
        font-size: 30px;
        padding: 0;
        font-weight: normal;
    }
    h2 {
        font-size: 40px;
    }
}
.waveAnimation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    opacity: 0.5;
}


  

.ourWorkBox {
    display: block;
    padding: 30px;
    box-shadow: 8.572px 5.15px 6.8px 1.2px rgba(54, 56, 56, 0.05) !important;
    position: relative;
    overflow: hidden;
    background: #fff !important;
    z-index: 1;
    height: 100%;
    border-radius: 10px !important;
    span {
        width: 80px;
        height: 80px;
        text-align: center;
        background: $bg-white;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        position: relative;
        img {
            width: 45px;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    h3 {
        transition: all ease-in-out 0.6s;
        font-size: 1.2rem;
    }
    small {
        border: $bg-2 1px solid;
        border-radius: 30px;
        padding: 5px 10px;
        transition: all ease-in-out 0.8s;
    }

    &:after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out;
        background: #d248fa;
        background: -moz-linear-gradient(-45deg, #d248fa 0%, #fb5d36 100%);
        background: -webkit-linear-gradient(-45deg, #d248fa 0%, #fb5d36 100%);
        background: linear-gradient(135deg, #d248fa 0%, #fb5d36 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d248fa', endColorstr='#fb5d36',GradientType=1 );
    }
    &:hover {
        &:after {
            transform-origin: left;
            transform: scaleX(1);
        }
        h3 {
            color: #fff;
        }
        small {
            border-color: $bg-white;
            color: $bg-white;
        }
    }
}

.tourPacLeft {
    height: 260px;
    background-size: cover !important;
    position: relative;
    border-radius: 16px 0 0 16px;
    overflow: hidden;
    .dolerPp {
        opacity: 1;
        visibility: visible;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
    }
}
.dudiAct {
    li {
        span {
            display: inline-block;
            width: 18px;
            vertical-align: middle;
            margin-right: 3px;
            img {
                width: 100%;
                display: inline-block;
            }
        }
    }
}
.ptsWrap {
    background: #fff;
    padding: 15px;
    border-radius: 0 0 16px 16px;
    h4 {
        line-height: 1;
        a {
            color: #444;
            font-size: 18px;
        }
    }
}
.ptsWrapTop {
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    >img{
        height: 250px;
        object-fit: cover;
    }
    .newSpan {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;
        z-index: 1;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        &.bgParple {
            background: #92278f;
        }
        &.bgGreen {
            background: #39b54a;
        }
        // &:after {
        //     content: '';
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -65%);
        //     width: 100px;
        //     height: 100px;
        //     border-radius: 50%;
        //     z-index: -1;
        // }
    }
    // .bgParple {
    //     &:after {
    //         background: #92278f;
    //     }
    // }
    // .bgGreen {
    //     &:after {
    //         background: #39b54a;
    //     }
    // }
    .dolerPp {
        opacity: 1;
        visibility: visible;
        position: absolute;
        bottom: 60px;
        width: 100%;
    }
}
.aboutInner {
    h2 {
        font-size: 200px;
    }
}
.aboutIcons {
    li {
        flex: 0 0 16%;
        max-width: 16%;
        font-size: 16px;
        white-space: nowrap;
        font-weight: 700;
        &:not(:last-child) {
            margin-right: 20px;
        }
        &:first-child{
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 1.5rem;
        }
    }
}
.roundIcon {
    background: #d656ee;
    background: -moz-linear-gradient(-45deg, #d656ee 0%, #f75e50 100%);
    background: -webkit-linear-gradient(-45deg, #d656ee 0%, #f75e50 100%);
    background: linear-gradient(135deg, #d656ee 0%, #f75e50 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d656ee', endColorstr='#f75e50',GradientType=1 );
    height: 66px;
    width: 66px;
    line-height: 66px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    padding: 15px;
    margin-bottom: 10px;
}
.aboutSlider {
    .aboutInnerBox {
        display: block;
        box-shadow: rgba(0,0,0,.3) 0px 5px 16px -9px;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}


.aboutSlider .owl-stage-outer {
    padding: 20px 0;
}
.pinkBg {
    background: #fce8f4 !important;
}
.aboutSlider {
    position: relative;
    .owl-prev,
    .owl-next {
        position: absolute;
        top: 50%;
        width: 34px;
        height: 34px;
        border-radius: 50% !important;
        margin: 0;
        transform: translateY(calc(-50% - 7px));
        &.disabled {
            display: none !important;
        }
        span {
            font-size: 30px;
            line-height: 20px;
        }
    }
    .owl-prev {
        left: -44px;
    }
    .owl-next {
        right: -44px;
    }
}
.sellerParalax {
    height: 450px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #194204;
        opacity: 0.6;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 74px;
        width: 100%;
        background: url(../../../public/images/best-seller-pattern.png) center center no-repeat;
        z-index: 1;
        background-size: cover;
    }
}
.bestSellerRight {
    position: absolute;
    top: 4px;
    right: -7px;
    padding: 19px;
    z-index: 1;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    transform: rotate(46deg);
    display: block;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    width: 100px;

    &:after {
        content: '';
        position: absolute;
        top: -36px;
        right: -12px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        background: #fb5d36;
        width: 120px;
        height: 120px;
        z-index: -1;
        border-radius: 50% 0 50% 50%;
    }
}
.bestSellerLeft {
    position: absolute;
    top: 22px;
    left: 22px;
    z-index: 1;
    max-width: 300px;
    span {
        display: block;
        img {
            max-width: 100%;
            display: block;
        }
    }
}
.bestSellerDescArea {
    margin-top: -300px;
    position: relative;
    z-index: 1;
}
.bestSellerRoundImg {
    img {
        width: 100%;
        display: block;
    }
}

.bsrImgWrap {
    width: 100%;
    border-radius: 100% !important;
    overflow: hidden;
    padding: 20px;
    background: #fff !important;
    box-shadow: rgba(0, 0, 0, 0.4) 0 0 20px -10px !important;
}
.bestSellerRoundImg {
    img {
        width: 100%;
        display: block;
        border-radius: 50%;
    }
}
.bsri-1 {
    position: relative;
    right: -70px;
}
.bsri-2 {
    position: relative;
    z-index: 1;
}
.bsri-3 {
    position: relative;
    left: -70px;
}
.ratingArea {
    li {
        white-space: nowrap;
        strong {
            white-space: nowrap;
        }
    }
}
.bestSellerActi {
    .dudiAct {
        padding: 10px 0;
        li {
            font-size: 26px;
            color: #444444;
            text-align: center;
            padding: 0 30px;
            font-weight: 700;
            span {
                width: 60px;
                margin-right: 15px;
            }
        }
    }
}
.oldNewRate {
    text-align: center;

    small {
        text-decoration: line-through;
        font-size: 30px;
        color: #82807f;
        font-weight: 400;
    }
    span {
        display: block;
        border-radius: 50px;
        line-height: 56px;
        border: #f97150 5px solid;
        padding: 0 50px;
        font-size: 36px;
        color: #f97250;
        font-weight: 400;
    }
}
.mb-0 {
    margin-bottom: 0 !important;
}
.pl-15 {
    padding-left: 15px;
}
.lientSaysParalax {
    padding: 4rem 0 14rem;
}
.clientSayBox {
    height: 100%;
    position: relative;
    padding: 30px 30px 80px;
    border-radius: 20px;
    background: url(../../../public/images/client-say-box-bg.png) bottom -15px right 50px #fff no-repeat !important;
    &.Height-400 {
        min-height: 350px;
    }
    .cbRateDate {
        position: absolute;
        bottom: 27px;
        width: 100%;
        left: 0;
        padding: 0 29px;
    }

}
.fLink * {
    color: #fff !important;
    margin: 0;
}

.bestSellerDescBtm {
    padding-bottom: 4rem;
}
.aboutBan,
.StepBan {
    z-index: 1;
}
.aboutBan:after,
.StepBan:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    left: 0;
    top: 0;
}
.aboutBanSlogan {
    z-index: 1;
}

.aboutBan::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    background: url(../../../public/images/about/1.svg) no-repeat;

    left: 0;
    bottom: 0;
    z-index: 1;
    background-size: cover;
}
.aboutSec-2 {
    padding: 2rem 0;
}
.counterCard {
    margin: 0 auto;
}
.counterSection {
    padding: 2rem 0;
}
.aboutSec-3 {
    padding: 3rem 0;
    overflow: hidden;
}
.missionImg {
    padding-left: 150px !important;
    img {
        display: block;
        width: 100%;
    }
    .missionMid {
        box-shadow: rgba(255, 255, 255, 0.6) 0 0 20px;
        border-radius: 10px;
        overflow: hidden;
        height: 448px;
        width: 448px;
        img{
            height: 448px;
            width: 448px;
            object-fit: cover;
        }
    }
    .missionTop {
        position: absolute;
        top: -106px;
        right: 320px;
        box-shadow: none;
        border-radius: 10px;
        overflow: hidden;
        width: 290px;
        height: 290px;
        img{
            height: 290px;
            object-fit: cover;
        }
    }
    .missionBtm {
        position: absolute;
        bottom: -106px;
        right: 320px;
        z-index: -1;
        box-shadow: none;
        border-radius: 10px;
        overflow: hidden;
        width: 290px;
        height: 290px;
        img{
            height: 290px;
            object-fit: cover;
        }
    }
}
.serviceExcellence {
    padding: 3rem 0 0;
}
.roundIcon-2 {
    height: 50px;
    width: 50px;
    display: block;
    margin-bottom: 1.6rem;
    img{
        height: 100%;
        display: block;
    }
}
.mainActivityParalax {
    padding: 2rem 0;
}

.weAreMember {
    padding: 1rem 0 8.5rem;
}

.aboutBan {
    img {
        width: 100%;
    }
}
.MuiButtonBase-root.Mui-expanded {
    background: #f97150 !important;
    color: #fff !important;
}
.faqAccordion {
    .MuiButtonBase-root.Mui-expanded {
        background: #fff !important;
        color: #333 !important;
    }
}
.acordianSrc {
    margin-bottom: 25px;
    .acordianSrcInput {
        height: 70px;
        border-radius: 50px;
        width: 100%;
        padding: 0 30px;
        border: none;
        font-size: 15px;
        font-family: 'Inter';
        box-shadow: rgba(0, 0, 0, 0.15) 0 18px 10px -5px;
    }
    .acordianSrcBtn {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 54px;
        height: 54px;
        line-height: 54px;
        text-align: center;
        background: #b7b7b7;
        color: #fff;
        border-radius: 50%;
        border: none;
        font-size: 30px;
    }
}
.MuiDrawer-paper {
    background: #b85036 !important;
}

.testHeader {
    background-color: transparent !important;
    position: absolute !important;
    top: 0px;
    z-index: 999999;
}
.test {
    background-color: #173850 !important;
    position: fixed !important;
    top: 0px;
}

.itinerayBann {
    .MuiRating-icon.MuiRating-iconEmpty {
        color: #faaf00;
    }
}

.overViewArea {
    // .css-h0q0iv-MuiButtonBase-root-MuiTab-root {
    //     min-height: 52px !important;
    //     font-weight: bold;
    //     font-family: Montserrat;
    //     font-size: 13px;
    //     letter-spacing: 0;
    //     padding: 0 10px;
    // }
    // .css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
    //     color: #f97150 !important;
    // }
    // .css-1aquho2-MuiTabs-indicator {
    //     background-color: #f97150 !important;
    // }
    // .css-i4bv87-MuiSvgIcon-root {
    //     width: 0.8em;
    //     height: 0.8em;
    // }
    ul{
        li{
            a{
                // min-height: 52px !important;
                font-weight: 700;
                font-family: Montserrat;
                text-transform: uppercase;
                font-size: 13px;
                letter-spacing: 0;
                padding: 0 10px;
                display: block;
                line-height: 52px;
                transition: all .5s;
                color: #636363;
                position: relative;
                &::after{
                    content: "";
                    position: absolute; 
                    bottom: 0;
                    left: 0;
                    height: 2px;
                    width: 100%;
                    background: #f97150;
                    opacity: 0;
                    visibility: hidden;
                }
                svg{
                    display: inline-block;
                    margin-right: 8px;
                    vertical-align: middle;
                }
                &.active-scroll-spy {
                    color: #f97150;
                    &::after{
                        opacity: 1;
                        visibility: visible;
                    }
                    
                  } 
                
                  
            }
        }
    }
}
.acc {
    z-index: 999999 !important;
}

// .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
// 	width: 60%;
// }
.megaMenu {
    background: #f6f6f6;
    max-width: 800px;
    width: 100%;
}
.megaMenuLeft {
    flex: 0 0 200px;
    max-width: 200px;
    border-right: #eee 1px solid;
    background: #fff;
}

.megaMenuRight {
    flex: 0 0 calc(100% - 200px);
    max-width: calc(100% - 200px);
    padding: 30px 50px 30px 30px;
    font-size: 13px;
    background: #f6f6f6;
    .bdrLeft {
        border-left: #ccc 1px solid;
    }
    .css-1sqktie-MuiButtonBase-root-MuiListItemButton-root {
        &:hover {
            background-color: transparent;
        }
    }

    .noWrap {
        white-space: nowrap;
    }
}
.font-12 {
    font-size: 12px !important;
}

.itineraryRight {
    position: sticky;
    top: 120px;
}

.tsReboon {
    span {
        background: #444;
        display: inline-block;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        font-style: italic;
        padding: 7px 60px;
        border-radius: 0 0 10px 10px;
        line-height: 1.8;
    }
}

.allInclusive {
    font-size: 14px !important;
    color: #fff;
    text-align: center;
    font-family: Montserrat !important;
    margin-bottom: 5px !important;
    span {
        font-weight: 700;
        font-size: 18px;
        display: inline-block;
    }
}
// .datePerson .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
//     border-color: #fff !important;
//     font-family: Montserrat;
// }

// .datePerson .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
//     -webkit-transform: translate(14px, 6px) scale(1) !important;
//     -moz-transform: translate(14px, 6px) scale(1) !important;
//     -ms-transform: translate(14px, 6px) scale(1) !important;
//     transform: translate(14px, 6px) scale(1) !important;
//     font-family: Montserrat;
//     font-size: 14px;
// }

// .datePerson .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
// .datePerson .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
//     padding: 6.5px 14px !important;
//     font-family: Montserrat;
// }
// .datePerson .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
//     color: rgba(255, 255, 255, 0.6) !important;
//     font-family: Montserrat;
// }
// .datePerson .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
//     color: #fff !important;
//     font-family: Montserrat;
// }

// .topSellerBox .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
//     color: #fff !important;
//     font-family: Montserrat;
// }
.datePerson {
    label{
        color: #fff !important;
        font-size: 14px;
    }
    input{
        color: #fff !important;
    }
    svg{
        color: #fff !important;
    }

}

.whyBookUs {
    p {
        margin: 15px 0;
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: 500;
    }
}

.overViewArea {
    position: sticky;
    top: 0;
    z-index: 9;
}

.pericePersonDd .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 11.5px 14px;
}
.pericePersonDd .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: $bg-1;
}

.pericePersonDd .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    -webkit-transform: translate(14px, 10px) scale(1) !important;
    -moz-transform: translate(14px, 10px) scale(1) !important;
    -ms-transform: translate(14px, 10px) scale(1) !important;
    transform: translate(14px, 10px) scale(1) !important;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 700;
}

.tourPackageSlider {
    position: relative;
    .owl-prev,
    .owl-next {
        position: absolute;
        top: 50%;
        width: 34px;
        height: 34px;
        border-radius: 50% !important;
        margin: 0;
        transform: translateY(calc(-50% - 7px));
        &.disabled {
            display: none !important;
        }
        span {
            font-size: 30px;
            line-height: 20px;
        }
    }
    .owl-prev {
        left: -44px;
    }
    .owl-next {
        right: -44px;
    }
}

.fromUsppBtn {
    color: #444444 !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'Montserrat' !important;
    span {
        font-weight: 400 !important;
        font-size: 60% !important;
        margin-right: 10px;
    }
    sub {
        font-size: 50% !important;
        margin-left: 5px;
    }
}
.ddMenu {
    font-family: 'Montserrat' !important;
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: -10px;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 20px 20px;
        border-color: transparent transparent #fff transparent;
    }
}
.mmLink {
    background-color: transparent !important;
    &:hover {
        color: $bg-1 !important;
    }
}
.siteNav {
    padding: 15px 0;
}

.siteNav {
    .active {
        &:after {
            content: '';
            position: absolute;
            bottom: -28px;
            left: 50%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px 20px;
            border-color: transparent transparent #fff transparent;
            transform: translateX(-50%);
        }
    }
}
.menuInner-2 {
    width: 500px !important;
}
.menuInner-3 {
    width: 200px !important;
}
.smallMenu {
    padding: 15px;
    position: relative;
    // &::after{
    //     content: "";
    //     position: absolute;
    //     width: 30px;
    //     height: 30px;
    //     left: 0;
    //     top: -3px;
    //     width: 0;
    //     height: 0;
    //     border-style: solid;
    //     border-width: 0 5px 5px 5px;
    //     border-color: transparent transparent #007bff transparent;
    // }
}
.gierList ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.gierList ul li {
    flex: 0 0 33.333%;
    margin: 10px 0;
    max-width: 33.333%;
    position: relative;
    padding-left: 20px;
}
.gierList ul li:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    left: 0;
    top: 6px;
    border-radius: 50%;
    background: $bg-1;
}
.gierList ul li:before {
    content: '';
    position: absolute;
    left: 3px;
    top: 9px;
    background: $bg-white;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    z-index: 1;
}

.megamenuOuter {
    top: 0 !important;
    right: 200px !important;
}
.offerDiscountTable {
    table {
        border: none !important;
        th,
        td {
            padding: 0.5rem 2rem;
            border: none !important;
            text-align: center;
            .ltProperties{
                margin: 0 5px;
                display: inline-block;
                vertical-align: middle;
                font-size: 90% !important;
                color: #929191;
            }
        }
    }
}
.roundNumber {
    margin-top: 1rem;
    // .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    //     width: 2em !important;
    //     height: 2em;
    //     box-shadow: rgba(0, 0, 0, 0.4) 0 0 20px -5px;
    //     border-radius: 50%;
    //     &.Mui-active {
    //         color: $bg-1;
    //     }
    //     &.Mui-completed {
    //         color: $bg-1;
    //     }
    // }
    // .css-zpcwqm-MuiStepConnector-root {
    //     top: 24px;
    //     left: calc(-50% + 36px);
    //     right: calc(50% + 36px);
    //     .css-z7uhs0-MuiStepConnector-line {
    //         display: block;
    //         border-color: #cccccc;
    //         border-top-style: solid;
    //         border-top-width: 2px;
    //     }
    // }
}
// .contDtlsForm {
//     .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
//         padding: 8.5px 14px;
//         font-size: 13px;
//     }
//     .contDtlsForm {
//         .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
//             padding: 8.5px 32px 14px 8.5px !important;
//             font-size: 13px;
//         }
//     }
// }
.stepArea {
    padding: 1rem 0 7rem;
}
.stepArea * {
    font-family: 'Montserrat' !important;
}
.additionalService {
    table {
        td,
        th {
            border: none;
            padding: 0;
        }
    }
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 4px 14px;
        font-size: 13px;
    }
}
// .contDtlsForm {
//     .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
//         padding: 6.2px 14px;
//         font-size: 13px;
//     }
// }
.colOrange {
    color: #f97150 !important;
}

.addressFlex {
    ul {
        .info {
            flex: 0 0 120px;
            max-width: 120px;
        }
        .value {
            flex-grow: 1;
        }
        &:not(:last-child) {
            margin-bottom: 0.8rem;
        }
    }
}
.paymentAreaInner {
    background: #fff;
    width: 100%;
    padding: 1rem 2rem;
}
.cardImg {
    img {
        max-width: 100%;
        display: block;
    }
}
.nxtBtnStep {
    background: $bg-1 !important;
    color: $bg-white !important;
    padding: 6px 34px !important;
}
.finishBtnStep {
    background: #737373 !important;
    color: $bg-white !important;
    padding: 6px 34px !important;
}
.forSticky {
    position: sticky;
    top: 20px;
}

.stepWrapArea {
    padding: 2rem 0 7rem;
}
.tabLeft {
    width: 100%;
    button {
        text-align: left !important;
        align-items: start !important;
        text-transform: capitalize;
        font-size: 1.1rem;
        color: #444;
        padding: 0;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
        &.Mui-selected {
            color: $bg-1;
        }
    }
    //     .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    //         -webkit-align-items: left !important;
    // -webkit-box-align: left !important;
    // -ms-flex-align: left !important;

    //     }
}

.tabDtls {
    // border: #ddd 1px solid
    .css-19kzrtu {
        padding: 0;
    }
    .css-ahj2mt-MuiTypography-root {
        font-size: 1.1rem;
    }
}

.tabLeft {
    border: #ddd 1px solid;
    border-radius: 6px;
    position: sticky;
    top: 50px;
    padding: 1rem;
}

.filterWrap {
    background: $bg-8;
}
.filterLeft {
    background: $bg-white;
    border-radius: 10px;
    border: #ddd 1px solid;
}

.filterLeftInner {
    padding: 1.2rem;
    border-top: #ddd 1px solid;
}

.filterLeftInner {
    ul {
        li {
            span {
                font-family: "Montserrat";
                font-weight: 700;
            }
        }
    }
}

.yellowColor {
    color: $colorYellow;
}
.grayColor {
    color: $bg-6;
}
.SortByTop {
    padding: 0 8px;
    ul {
        li {
            flex: 1 0 0%;
            &:not(:last-child) {
                margin-right: 0.8rem;
            }
        }
    }
}
.ptsWrapTop > img {
    display: block;
    width: 100%;
}
.filterWrap {
    padding: 0 0 7rem;
}

.mt-5 {
    margin-top: 5rem;
}

.pegiTabColor {
    .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        color: #fff;
        background-color: #f26522;
    }
}
.radio-wrap {
    position: relative;
    margin: 3rem 0;
    .inside {
        background-color: #fff;
        padding: 60px 0 30px;
        transition: all 0.4s ease;
        position: relative;
        text-align: center;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.1) 0 8px 20px -10px;
        span {
            position: absolute;
            left: 50%;
            top: -40px;
            height: 80px;
            width: 80px;
            background: #f4f8fd;
            transform: translateX(-50%);
            line-height: 100px;
            border-radius: 50%;
            transition: all 0.4s ease;
            border: #fff 3px solid;
            svg {
                font-size: 30px;
                transition: all 0.4s ease;
            }
        }
    }
    >span {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
        height: 100%;
        width: 100%;
    }
}

.Mui-checked {
    color: #1976d2;
}

.radio-wrap.baranjhatta .inside , 
.radio-wrap.baranjhatta span  {
    border-color: #fff !important;
    background-color: #ffd7cd !important;
}
// .radio-wrap .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked ~ .inside,
// .radio-wrap .css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover ~ .inside,
// .radio-wrap .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked ~ .inside span,
// .radio-wrap .css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover ~ .inside span {
//     border-color: #fff !important;
//     background-color: #ffd7cd !important;
// }
.radio-wrap .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked ~ .inside svg,
.radio-wrap .css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover ~ .inside svg,
.radio-wrap .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked ~ .inside span svg,
.radio-wrap .css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover ~ .inside span svg {
    // color: #fff !important;
}

.familyDropDown {
    ul {
        li {
            flex: 0 0 200px;
            max-width: 200px;
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}

.checkbox-wrap {
    position: relative;
    .inside {
        background-color: #fff;
        // padding: 10px;
        transition: all 0.4s ease;
        position: relative;
        text-align: center;
        border-radius: 5px;
        // box-shadow: rgba(0,0,0,.1) 0 8px 20px -10px;
        border: #ddd 1px solid;
    }
}

// .checkbox-wrap .MuiCheckbox-root.Mui-checked {
//     width: 100%;
//     margin: 0 !important;
// }
.checkbox-wrap input:checked ~ .inside {
    border-color: #0260c0 !important;
    background-color: #0260c0 !important;
}

.lookingFor {
    li.radio-wrap {
        margin: 0.5rem 0;
        &:not(:last-child) {
            margin-right: 1.1rem;
        }
        .inside {
            padding: 0.7rem 2rem;
            display: flex;
            align-items: center;
            border: #eee 1px solid;
            span {
                position: static;
                height: 20px;
                width: 20px;
                background: #fff;
                transform: translateX(0);
                line-height: 18px;
                border: #9d9d9d 1px solid;
                margin-right: 0.8rem;
                svg {
                    font-size: 10px;
                    color: #fff;
                }
            }
            h6 {
                padding: 0 !important;
            }
        }
        .Mui-checked ~ .inside span {
            border-color: #373737 !important;
            svg {
                color: #373737;
            }
        }
        &:hover {
            .css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover ~ .inside span {
                border-color: #373737 !important;
                svg {
                    color: #373737;
                }
            }
        }
    }
}
.personalInfo {
    margin: 3rem auto 0;
    max-width: 700px;
}

.rangeSlider {
    margin: 0 auto;
    max-width: 950px;
    width: 100%;
    background: #f6f6f6;
    padding: 2rem;
}
.banIcon {
    background: #f97150;
    background: #f97150;
    display: block;
    width: 90px;
    height: 90px;
    color: #fff;
    font-size: 60px;
    line-height: 102px;
    border-radius: 6px;
    text-align: center;
    position: absolute;
    bottom: 45px;
    right: 100px;
    z-index: 1;
}

.BreakdownTable {
    table {
        td {
            padding: 0.8rem 0;
        }
    }
}

.tbn {
    border-bottom: none !important;
}

.signInBtn {
    font-size: 0.675rem;
    line-height: 2.5;
    width: 100%;
    background: #f97150;
    border-radius: 6px;
    border: none;
    color: #fff;
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 1rem;
    &:hover {
        background: #333;
        color: #fff;
    }
}
.signInBtnBdr {
    font-size: 0.675rem;
    line-height: 2.5;
    width: 100%;
    background: #fff;
    border: #f97150 1px solid;
    border-radius: 6px;
    color: #f97150;
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 1rem;
    &:hover {
        background: #f97150;
        color: #fff;
    }
}

.forgetPassword {
    color: #333;
    font-weight: 600;
    &:hover {
        color: #f97150;
    }
}

.ViewBookingBanner {
    height: 400px;
    width: 100%;
    position: relative;
    background-size: cover !important;
    z-index: 1;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
    }
}

.viewBooking {
    padding: 2.5rem 0 8rem;
}
.mailPrint {
    ul {
        li {
            &:not(:last-child) {
                margin-right: 1.2rem;
            }
            a {
                color: #333;
                font-weight: 600;
                svg {
                    color: #f97150;
                    font-size: 18px;
                    display: inline-block;
                    vertical-align: middle;
                }
                &:hover {
                    color: #f97150;
                }
            }
        }
    }
}
.viewImg {
    position: sticky;
    top: 20px;
    img {
        width: 100%;
        display: block;
    }
}

.campTrek {
    ul {
        &:not(:last-child) {
            margin-bottom: 3px;
        }
        li.campTracInfo {
            flex: 0 0 120px;
            max-width: 120px;
        }
        li.campTracVal {
            flex: 0 0 calc(100% - 150px);
            max-width: calc(100% - 150px);
            position: relative;
            padding-left: 14px;
            font-weight: 500;
            &:after {
                content: ':';
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}
.campAmountArea {
    background: #fff0ed;
    padding: 1.5rem;
    border-radius: 10px;
    .campAmount {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        li {
            font-size: 1rem;
            font-weight: 600;
            position: relative;
            &:first-child {
                margin-right: 10px;
                flex: 130px;
                max-width: 130px;
            }
            &:last-child {
                color: #f97150;
                &:before {
                    content: ':';
                    position: absolute;
                    left: -15px;
                }
            }
        }
    }
}

.travelerBooker {
    ul {
        &:not(:last-child) {
            margin-bottom: 0.8rem;
        }
        li {
            font-size: 18px;
            font-weight: 600;
            position: relative;
        }
        .travelerBookerInfo {
            flex: 190px;
            max-width: 190px;
        }
        .travelerBookerVal {
            &:before {
                content: ':';
                position: absolute;
                left: -15px;
            }
        }
    }
}
.policyAccordion {
    .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
        box-shadow: none;
        &:before {
            display: none;
        }
    }
}
.hoursGuest {
    margin: 1rem 0;
    ul {
        li {
            font-weight: 600;
            &:not(:last-child) {
                margin-right: 1.25rem;
            }
            svg {
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                font-size: 18px;
                color: #f97150;
            }
        }
    }
}
.mealsBreakfast {
    p {
        svg {
            margin-right: 10px;
            color: #f97150;
        }
    }
}

.includedExcluded {
    margin-top: 1.25rem;
    ul {
        margin-top: 10px;
        li {
            padding-left: 30px;
            position: relative;
            &:not(:last-child) {
                margin-bottom: 0.8rem;
            }
            &::after {
                content: '\f058';
                font-family: 'Font Awesome 5 free';
                font-weight: 900;
                position: absolute;
                left: 0;
                color: #f97150;
            }
        }
    }
}

.printItinerary {
    color: #000;
    font-weight: 700 !important;
    font-family: 'Montserrat' !important;
    svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        color: #f97150;
    }
    &:hover {
        color: #f97150;
    }
}

.blogBanner,
.contentPageBann {
    height: 300px;
    width: 100%;
    position: relative;
    background-size: cover !important;
    z-index: 1;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.56);
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

.blogList {
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 10px -5px;
    transition: all ease-in-out 0.4s;
    height: 100%;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.5) 0 5px 20px -7px;
    }

    .eventName {
        position: absolute;
        bottom: 0;
        line-height: 1;
        background: #fff;
        padding: 1rem 1.5rem;
        display: block;
        color: #000;
        font-weight: 600;
    }
}
.thumbnailContainer {
    position: relative;
    img {
        width: 100%;
        display: block;
        height: 260px;
        object-fit: cover;
    }
}
.postblockContent {
    h6 {
        padding-bottom: 0;
        a {
            color: #333;
            font-weight: 700;
            font-family: 'Montserrat';
            line-height: 1.2;
            &:hover {
                color: #f97150;
            }
        }
    }
    p {
        color: #9ca8af;
        font-family: 'Montserrat';
    }
    .readMore {
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-top: 1.25rem;
        border-bottom: #f97150 1px solid;
        padding: 5px 0;
        svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
            font-size: 16px;
            position: relative;
            top: -2px;
            color: #f97150;
        }
        &:hover {
            color: #f97150;
        }
    }
    .captionDate {
        color: #f97150;
        margin-bottom: 0.7rem;
        display: block;
        svg {
            margin-right: 5px;
        }
    }
}

.blogArea {
    padding-top: 2rem;
    padding-bottom: 7rem;
    position: relative;
    .blogFilter {
        position: absolute;
        right: 80px;
        top: 3.5rem;
        z-index: 1;
    }
}

.shareThis {
    ul {
        li {
            &:not(:last-child) {
                margin-right: 1rem;
            }
            a {
                color: #5e5d5d;
                font-size: 16px;
                border: #7d7d7d 1px solid;
                height: 40px;
                width: 40px;
                line-height: 43px;
                text-align: center;
                border-radius: 50%;
                display: block;
                svg {
                    display: inline-block;
                }
                &:hover {
                    background: #f97150;
                    color: #fff;
                    border-color: #f97150;
                }
            }
        }
    }
}
.blogTitle {
    ul {
        justify-content: center;
        margin-top: 0.6rem;
        li {
            font-size: 1rem;
            font-weight: 600;
            &:not(:last-child) {
                margin-right: 20px;
            }
            svg {
                width: 40px;
                height: 40px;
                background: #e1e1e1;
                color: #333;
                display: inline-block;
                margin-right: 5px;
                border-radius: 50%;
                padding: 10px;
                vertical-align: middle;
            }
        }
    }
}

.contentPageArea {
    padding-top: 1rem;
    padding-bottom: 3rem;
}
.rtbleft {
    span {
        img {
            display: block;
            width: 100%;
        }
    }
    .bestSeller {
        position: absolute;
        left: -8px;
        top: 5px;
        background: rgba(249, 113, 81, 0.74);
        color: #fff;
        padding: 2px 10px;
        font-size: 9px;
        font-weight: 400;
    }
}
.recommendedTourBox {
    &:not(:last-child) {
        margin-bottom: 13px;
        border-bottom: #ddd 1px solid;
        padding-bottom: 10px;
    }
}

.travelTags {
    background: #f2f2f2;
    padding: 1rem;
    margin-top: 1rem;
    ul {
        li {
            background: #fff;
            padding: 0.5rem 1rem;
            border-radius: 6px;
            border: #e5e5e5 1px solid;
            &:not(:last-child) {
                margin-right: 0.8rem;
            }
        }
    }
}

.commentsBoxInner {
    padding: 1.25rem 2rem;
    &:not(:last-child) {
        border-bottom: #ddd 1px solid;
    }
}

.recentPost {
    span {
        display: block;
        img {
            width: 100%;
            display: block;
            height: 80px;
            object-fit: cover;
        }
    }
}

.recentPost {
    padding: 1rem;
    &:not(:last-child) {
        border-bottom: #ddd 1px solid;
    }
    &:hover {
        background: #f9f9f9;
    }
}

.teamTitle {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    h3 {
        span {
            display: block;
            font-weight: 400;
        }
    }
}

.teamListImg {
    flex: 0 0 50%;
    max-width: 50%;
    overflow: hidden;
    height: 100%;
    a {
        display: block;
        height: 100%;
        img {
            display: block;
            width: 100%;
            transform: scale(1.06);
            transition: 0.4s;
            height: 100%;
            object-fit: cover;
        }
    }
}

.teamListDtls {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 1rem 1rem 1rem 1.5rem;
}

.teamBox {
    box-shadow: 0 0 68px 0 rgba(0, 0, 0, 0.08);
    height: 100%;
    &:hover {
        .teamListImg {
            img {
                transform: scale(1);
            }
        }
        .itemSocial {
            ul {
                li {
                    a {
                        visibility: visible;
                        transform: translateY(0);
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .redMore {
        color: #f97150;
        display: inline-block;
        vertical-align: middle;
        font-family: 'Montserrat';
        font-weight: 600;
        svg {
            vertical-align: middle;
            margin-left: 5px;
            color: #333;
        }
    }
}

.itemSocial {
    overflow: hidden;
    margin-bottom: 0.8rem;
    ul {
        li {
            &:not(:last-child) {
                margin-right: 0.5rem;
            }
            a {
                font-size: 1.1rem;
                color: #292f69;
                opacity: 0.5;
                transform: translateY(25px);
                transition: 0.4s;
                visibility: hidden;
            }
        }
    }
}

.rightPart {
    .whyBookUs {
        box-shadow: 0px 0px 68px 0px rgba(0, 0, 0, 0.08);
        &:first-child {
            margin-top: 0;
        }
    }
}

.singleImg {
    position: sticky;
    top: 80px;
    img {
        display: block;
        width: 100%;
    }
}
.teamIntro {
    margin-top: 5rem;
}

.parentContent {
    h4:not(:first-child) {
        margin-top: 2rem;
    }
}

.experience {
    margin-top: 2rem;
    border-bottom: #eee 1px solid;
    padding-bottom: 2rem;
}
.arrow {
    margin-top: 4rem;
    img {
        max-width: 60px;
    }
}

.personImg {
    flex: 0 0 268px;
    max-width: 268px;
    .personImgInner {
        // border-radius: 50%;
        // height: 268px;
        // overflow: hidden;
        position: sticky;
        top: 2rem;
        img {
            width: 100%;
            display: block;
        }
    }
}

.personDetails {
    flex:0 0  calc(100% - 268px);
    max-width:0 0  calc(100% - 268px);
    padding-left: 2rem;
}

.personDetailsInnerBox {
    margin-top: 2rem;
    &:not(:last-child) {
        border-bottom: #eee 1px solid;
        padding-bottom: 2rem;
    }
    ul {
        li {
            &:not(:last-child) {
                margin-right: 1rem;
            }
            a {
                font-size: 2rem;
                color: #292f69;
            }
        }
    }
}

.teamDtlsRight {
    position: sticky;
    top: 2rem;
    .whyBookUs {
        &:first-child {
            margin-top: 0;
        }
    }
}


.preload {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 9500;
	background-color: #ffffff;
}
.preload .logo-container {
	position: relative;
	display: block;
	overflow: hidden;
	width: 270px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.preload .logo-container .logo {
	width: 270px;
	z-index: 9002;
	position: relative;
	display: block;
}
.preload .logo-container .logo-curtain {
	position: absolute;
	right: 1px;
	left: 1px;
	bottom: 1%;
	top: 1px;
	z-index: 9001;
	overflow: hidden;
	// opacity: 0;
	background-color: #eeeeec;
}
.preload .logo-container .logo-curtain div {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 100%;
	// background: url(../../assets/images/common-image/background-pixel.png);
    background: #f97150;
	// background-size: cover;
	// background: #01c28d;
}

.rateRevMd {
    position: absolute;
    bottom: 22px;
    left: 0;
    width: 100%;
    padding: 0 2rem;
}
.rateRevMd-1 {
    position: absolute;
    bottom: 29px;
    left: 0;
    width: 100%;
    padding: 0 2.5rem 0 1.2rem;
    margin: 0 !important;
  }

.batch {
    position: absolute;
    width: auto;
    background: #f97150;
    color: #fff;
    padding: .3rem 1rem;
    top: 5px;
    font-weight: 700;
    &:before{
        content: "";
        position: absolute;
        top: 0px;
        left: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 11px 11px;
        border-color: transparent transparent #c64a2c transparent;
    }
    &:after{
        content: "";
        position: absolute;
        top: 0px;
        right: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 11px 0 0 11px;
        border-color: transparent transparent transparent #c64a2c;
    }
  }

 .noobjectFit {
    li {
        a{
            // background: #fff;
            // padding: 5px;
            // border-radius: 50%;
            img{
                object-fit: contain  !important;
            }
        }
    }
 } 

 .ProudjectFit {
    .MuiAvatar-root {
        background: #fff;
        padding: 5px;
        border-radius: 50%;
        img{
            object-fit: contain  !important;
        }
    }
    
 }

 .contactObjectfit{
    li{
        img{
            object-fit: contain  !important;
        }
    }
 }


 html {
    scroll-padding-top: 120px; /* height of your navbar */
  }
  
  .themeButton {
	background: #f97150;
	color: #fff;
	font-weight: 600;
    line-height:44px;
	padding: 0 22px;
	border-radius: 3px;
    font-family: 'Montserrat';
	font-size: 14px;
	letter-spacing: 0.02857em;
	text-transform: uppercase;
	box-shadow: none;
    white-space: nowrap;
    &:hover{
        background: #d04726;
    }
}

.missionVission {
    .mvLeft{
        flex: 0 0 70px;
        max-width: 70px;
        span{
            display: block;
            width: 100%;
            img{
                display: block;
                width: 100%;
            }
        }
    }
}
.popularToursSlider {
    display: block;
    padding: 10px;
    img{
        width: 100%;
        display: block;
    }
    &.tourImgMd{
        img{
            width: auto !important;
            display: block;
            max-height: 200px;
            margin: 0 auto;
        }
    }
}

.lineThrough {
    text-decoration: line-through;
}
.fromUsppBtn{
    .ltProperties {
        margin: 0 5px;
        display: inline-block;
        vertical-align: middle;
        font-size: 80% !important;
        color: #666;
    }
}
.ltProrerties-2 {
    font-weight: 400;
    color: #a4a2a2;
    line-height: 1;
    font-size: 85% !important;
    margin: 9px 0 0 !important;
  }

  .ltProrerties-3 {
    font-weight: 500 !important;
    color: #d1d1d1;
    line-height: 1;
    font-size: 85% !important;
    margin: 0 1px 0 3px !important;
  }
  .ptsInner {
    font-size: 12px;
    li {
        white-space: nowrap !important;
        font-size: 10px;
      }
  }

.shadowPad {
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 16px;
    padding: 15px;
  }

.starRateMd {
    margin-top: 10px;
}

.ovrScroll {
	max-height: 120px;
	overflow-y: auto;
    h2{
        font-size: 18px;
        padding-bottom: 5px;
    }
}
.ovrScroll-2 {
    max-height: 130px;
    overflow-y: auto;
    h2{
        font-size: 18px;
        padding-bottom: 5px;
    }
}
.megaMenuLeft {
    background: #fff;
    border:none !important;
    box-shadow: none !important;
    
    height: 100%;
    padding: 10px;
}

.megaMenuLeft nav {
    background-color: transparent;
    
}

.height-100 {
    height: 100px;
    overflow-y: auto;
  }

 .fontInter {
   font-family: "Inter" !important;
 } 

.notfound {
    height: 100vh;
    background: url(../../../public/images/404.jpg) no-repeat;
    text-align: center;
    background-size: cover;
    h2{
        font-size: 200px;
        color: #fff;
        opacity:.6;
        line-height: 1.2;
    }
    h5{
        font-size: 45px;
        color: #fff;
    }
    p{
        font-size: 18px;
        color: #fff;
    }
    ul{
        margin-top: 30px;
        li{
            &:not(:last-child){
                margin-right: 15px
            }
            a{
                background: #ddd;
                font-size: 16px;
                border-radius: 30px;
                padding: 10px 30px;
                display: block;
                color: #333;
            }
        }
    }
} 

.ourWorkInner {
    padding: 50px 80px;
    &.ourWorkInnPage{
        padding: 50px 80px 120px;
    }
    .sectionTitle {
        max-width: 1100px;
        margin: 0 auto;
        text-align: center
    }
}
.ourWorkInner {
    padding: 40px 80px;
}

.revModal .css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
	max-width: 800px;
	width: 100%;
}
.dashBoardSection {
    margin-bottom: 100px;
}
.navigationArea {
    flex: 0 0 250px;
    max-width: 250px;
    background: #fff;
    padding: 40px 0;
    box-shadow: rgba(0,0,0,.1)15px 0 30px -20px;
}
.dashBoardSection {
    // background: #f9f9f9;
    font-family: 'Montserrat';
}
.navigationBoxInner {
    margin-bottom: 1rem;
    ul{
        li{
            a{
                padding: 0.7rem 1rem;
                display: block;
                color: #6f6f6f;
                font-weight: 600;
                svg {
                    color: $bg-1;
                    position: relative;
                    top: 3px;
                    margin-right: .5rem;
                }
                &:hover{
                    background: #f3f3f3;
                }
            }
        }
    }
    h5{
        padding: .5rem 1rem;
    }
}

.signOutBox {
    a{
        padding: 0.7rem 1rem;
        display: block;
        color: #333;
        background: #f3f3f3;
        font-weight: bold;
        svg {
            color: $bg-1;
            position: relative;
            top: 2px;
            margin-right: .5rem;
        }
        &:hover{
            background: #e2e2e2;
        }
    }
}

.dbRight {
    flex: 1 0 0%;
    padding: 40px;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 180px;
        width: 100%;
        background: #fff7f5;
        z-index: -1;
        left: 0;
      }
  }
 .cmnWhtSadow {
    background: $bg-white;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 10px 30px -10px;
 }

 .boxHeader {
    border-bottom: #bdb9b9 1px dashed;
    margin-bottom: 20px;
    padding-bottom: 10px;
    h5{
        padding: 0;
    }
    .boxHeaderRight {
        a{
           color: $bg-1;
           font-weight: bold;
           display: block;
           svg {
            color: $bg-3;
            margin-right:5px;
           }
        }
        
    }
 }

 .proFileImg {
    flex: 0 0 150px;
    max-width: 100%;
    span{
        display: block;
        img{
            width: 100%;
            display: block;
        }
    }
 }

 .profileDesk {
    flex: 1 0 0%;
    max-width: 100%;
    padding: 0 40px;
    ul{
        li{
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 20px;
            font-weight: 600;
            span{
                display: inline-block;
            }
            span.value{
                font-weight: normal;
                margin-right: 10px
            }
            span.info{
                width: 90px;
            }
        }
    }
 }

 .me-3 {
    margin-left: 15px;
 }

 .breadeCambs {
    margin-bottom: 30px;
 }

 .myProfileInner {
    max-width: 600px;
    margin: 60px auto;
    h3 {
        margin-bottom: 30px;
    }
 }
.textCenter {
    text-align: center
}
.uploadImg {
    margin-bottom: 30px;
    flex: 0 0 250px;
    max-width: 250px;
    span{
        display: block;
        width: 100%;
        margin-bottom: 30px;
        img{
            width: 100%;
            display: block;
        }
    }
}
.myProfileRight {
    flex: 1 0 0%;
    padding-left: 40px;
  }
.w-100 {
    width: 100%;
}

.boxBodyNav {
    margin-bottom: 2rem;
    ul{
        li{
            position: relative;
            &:not(:last-child){
                margin-right: 25px;
                &::after{
                    content: "";
                    position: absolute;
                    width: 3px;
                    height: 2px;
                    background: $bg-1;
                    top: 50%;
                    right: -14px;
                }
            }
            a{
                color: $bg-3;
                text-decoration: none;
                font-weight: 600;
                font-size: 14px;
                &:hover , &.active{
                    color: $bg-1;
                }
            }
        }
    }
}

.accountBg {
    height: 204px;
    width: 100%;
    position: relative;
    background-size: cover !important;
    z-index: 1;
    &:after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.56);
        width: 100%;
        height: 100%;
        z-index: -1;
    }
  }
  .boxBodyTable {
    .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
        box-shadow: none;
        border: #ddd 1px solid;
    }
    th {
        font-weight: 600;
        font-family: 'Montserrat';
        color: $bg-3;
      }
      td{
        font-family: 'Montserrat';
      }
  }
  .NeedHelp {
    padding: 30px 10px 0;
    text-align: center;
    font-family: 'Montserrat';
    ul{
        li{
            font-size: 13px;
            font-family: 'Montserrat';
            &:not(:last-child){
                margin-bottom: 10px
            }
            svg {
                color: $bg-3;
                position: relative;
                top: 2px;
                margin-right: 0.2rem;
              }
            a{
                color: $bg-1;
            }
        }
    }
  } 
 .teamSkillBtm {
    max-width: 900px;
    width: 100%;
    margin: 4rem 0 6rem;
 } 
  
.gateInTouch {
    padding: 2rem 0;
    overflow-x: hidden;
}

.toggleMenu {
    display: none;
    font-weight: bold;
    font-size: 16px;
    color: #333
}


.mobileFilterShowHideLink {
    display: none;
    font-weight: bold;
    color: #fff;
    padding: 8px 10px;
    background: #f97150;
    border-radius: 6px;
    .mobileFilterShowHideLink svg {
        display: inline-block;
        position: relative;
        top: 2px;
    }
}
.aboutBanSlogan {
    p {
        font-family: 'Montserrat';
    }
}

.aboutSecAct {
    padding: 6rem 0;
}

.popularBoxInner {
    box-shadow: rgba(0,0,0,0.6) 0 0 20px -10px;
    border-radius: 10px;
}
.loginLogo {
    img{
        max-width: 100%;
        display: block;
    }
}
.px-10 {
    padding-right: 2.5rem!important;
    padding-left: 2.5rem!important;
}

.signInBdrBtn {
    border: $bg-1 1px solid;
    text-align: center;
    width: 100%;
    background: #f6f6f6;
    color:#333;
    font-weight: 700;
    padding: 6px 10px;
    border-radius: 6px;
}
.reviewSendBtn {
    ul{
        width: 100%;
        max-width: 400px;
        li{
            width: 100%;
            &:not(:last-child){
                margin-bottom: 16px;
            }
            button{
                width: 100%,
            }
        }
    }
}



@import "media";
